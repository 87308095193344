import '../styles/Game.scss'
import { gameContext } from '../contexts'
import useGameContextValue from '../hooks/pages/Game/game'
import GameWrapper from '../components/Game/GameWrapper'
import { useTitle } from '../hooks/pages/layout'

export default function Game() {
    useTitle('Правовий квест "Віртуальний суд": Гра')

    return (
        <gameContext.Provider value={useGameContextValue()}>
            <GameWrapper />
        </gameContext.Provider>
    )
}
