import { Button, Result } from 'antd'
import { useCurrentTaskProgress } from '../../../hooks/pages/Game/utils'
import { usePutUserProgress } from '../../../hooks/store/users.api'

export default function TaskResult() {
    const currentTaskProgress = useCurrentTaskProgress()

    const putUserProgress = usePutUserProgress()

    return (
        <Result
            status="success"
            title="Задачу закінчено!"
            subTitle={`Кількість помилок: ${currentTaskProgress?.errors}`}
            extra={[
                <Button type="primary" key="console" onClick={() => putUserProgress(null)}>
                    Завершити
                </Button>
            ]}
            style={{ backgroundColor: '#ffffff', width: '50%', margin: 'auto' }}
        />
    )
}
