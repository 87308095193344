import { Form, FormInstance, Input } from 'antd'
import { useState } from 'react'
import { IActionInfo, IActionPostBody, IActionPutBody } from '../../../utils/interfaces/action'
import { validationRules } from '../../../utils/validation'

interface IActionFormProps {
    form: FormInstance<IActionPutBody | IActionPostBody>
    initialValues?: IActionInfo
}

export default function ActionForm({ form, initialValues }: IActionFormProps) {
    const [required] = useState(initialValues ? [] : [validationRules.REQUIRED()])

    return (
        <Form form={form} initialValues={initialValues}>
            <Form.Item name="title" rules={[...required]}>
                <Input placeholder="Назва" />
            </Form.Item>
            <Form.Item name="description">
                <Input.TextArea placeholder="Опис" rows={4} />
            </Form.Item>
            <Form.Item name="event">
                <Input.TextArea placeholder="Наступне питання" rows={4} />
            </Form.Item>
        </Form>
    )
}
