import { createApi } from '@reduxjs/toolkit/query/react'
import { IFetchSettingsResponse, IPutSettingsResponse, ISettings } from '../utils/interfaces/settings'
import { baseQueryWithRefresh } from '../utils/store'

export const settingsApi = createApi({
    reducerPath: 'settings/api',
    baseQuery: baseQueryWithRefresh,
    endpoints: (builder) => ({
        fetchSettings: builder.query<IFetchSettingsResponse, void>({
            query: () => ({
                url: '/api/private/settings'
            })
        }),

        putSettings: builder.mutation<
            IPutSettingsResponse,
            {
                settings: ISettings
            }
        >({
            query: ({ settings }) => ({
                method: 'PUT',
                url: '/api/private/settings',
                body: settings
            })
        })
    })
})

export const { useFetchSettingsQuery, usePutSettingsMutation } = settingsApi
