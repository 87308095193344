import { Form, FormInstance, Input } from 'antd'
import { useEffect, useState } from 'react'
import { ITaskInfo } from '../../../utils/interfaces/task'
import { validationRules } from '../../../utils/validation'
import ActionsManager from '../Actions/ActionsManager'
import RolesSelect from '../Roles/RolesSelect'
import LocationsSelect from '../Locations/LocationsSelect'

interface ITaskFormProps {
    form: FormInstance<any>
    initialValues: ITaskInfo
}

export default function TaskForm({ form, initialValues }: ITaskFormProps) {
    const [required] = useState(initialValues ? [] : [validationRules.REQUIRED()])

    useEffect(() => {
        if (!form.getFieldValue('title')) {
            form.setFieldValue('title', 'Судібне засідання')
        }
    }, [])

    return (
        <Form form={form} initialValues={initialValues}>
            <Form.Item name="title" rules={[...required]}>
                <Input placeholder="Назва" />
            </Form.Item>
            <Form.Item name="description">
                <Input.TextArea placeholder="Опис" rows={4} />
            </Form.Item>
            <Form.Item name="roleId" rules={[...required]}>
                <RolesSelect required={true} form={form} />
            </Form.Item>
            <Form.Item name="locationId" rules={[...required]}>
                <LocationsSelect required={true} form={form} />
            </Form.Item>
            <Form.Item name="actionIds">
                <ActionsManager form={form} />
            </Form.Item>
        </Form>
    )
}
