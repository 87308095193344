import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { useDeleteTask } from '../../../hooks/store/tasks.api'

interface IDeleteTaskBtnProps {
    id: string
}

export default function DeleteTaskBtn({ id }: IDeleteTaskBtnProps) {
    const deleteTask = useDeleteTask(id)

    return (
        <Popconfirm title="Ви дійсно хочете видалити задачу?" onConfirm={deleteTask} okText="Так" cancelText="Ні">
            <DeleteOutlined />
        </Popconfirm>
    )
}
