import { createApi } from '@reduxjs/toolkit/query/react'
import {
    IFetchRolesResponse,
    IRoleDeleteResponse,
    IRolePostBody,
    IRolePostResponse,
    IRolePutResponse,
    IRolePutBody
} from '../utils/interfaces/role'
import { baseQueryWithRefresh } from '../utils/store'

export const rolesApi = createApi({
    reducerPath: 'roles/api',
    baseQuery: baseQueryWithRefresh,
    endpoints: (builder) => ({
        postRole: builder.mutation<IRolePostResponse, { body: IRolePostBody }>({
            query: ({ body }) => ({
                method: 'POST',
                url: '/api/private/roles',
                body
            })
        }),
        fetchRoles: builder.query<IFetchRolesResponse, void>({
            query: () => ({
                url: '/api/private/roles'
            })
        }),
        putRole: builder.mutation<IRolePutResponse, { id: string; body: IRolePutBody }>({
            query: ({ id, body }) => ({
                method: 'PUT',
                url: `/api/private/roles/${id}`,
                body
            })
        }),
        deleteRole: builder.mutation<IRoleDeleteResponse, { id: string }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `/api/private/roles/${id}`
            })
        })
    })
})

export const { usePostRoleMutation, useFetchRolesQuery, usePutRoleMutation, useDeleteRoleMutation } = rolesApi
