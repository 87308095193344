import React, { SetStateAction, useContext, useEffect } from 'react'
import { constructorContext } from '../../contexts'
import {
    useDeleteRoleMutation,
    useFetchRolesQuery,
    usePostRoleMutation,
    usePutRoleMutation
} from '../../store/roles.api'
import { IRoleInfo, IRolePutBody } from '../../utils/interfaces/role'
import { errorNotification } from '../../utils/notifications'
import { useToken } from '../auth'

export function useFetchRoles(setRoles: React.Dispatch<SetStateAction<IRoleInfo[]>>) {
    const token = useToken()

    const fetchRolesQuery = useFetchRolesQuery(undefined, { skip: !token })

    useEffect(() => {
        if (fetchRolesQuery.data?.result) {
            setRoles(fetchRolesQuery.data.result)
        }
    }, [fetchRolesQuery.data])
}

export function usePostRole(callback: (newRoleId: string) => void) {
    const [roles, setRoles] = useContext(constructorContext).rolesState

    const [postRoleMutation] = usePostRoleMutation()

    return (title: string) => {
        postRoleMutation({
            body: {
                title
            }
        }).then((value: any) => {
            if (value.data) {
                const role = value?.data?.result
                setRoles([...roles, role])

                callback(role.id)
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Додати роль не вдалося')
            }
        })
    }
}

export function useDeleteRole() {
    const {
        rolesState: [roles, setRoles]
    } = useContext(constructorContext)

    const [deleteRoleMutation] = useDeleteRoleMutation()

    return (id: string) => {
        deleteRoleMutation({
            id
        }).then((value: any) => {
            if (value.data) {
                const newRoles = [...roles.filter((role) => !(role.id === id))]

                setRoles(newRoles)
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Видалити роль не вдалося')
            }
        })
    }
}

export function usePutRole(callback: () => void) {
    const [roles, setRoles] = useContext(constructorContext).rolesState

    const [putRoleMutation] = usePutRoleMutation()

    return (id: string, body: IRolePutBody) => {
        putRoleMutation({
            id,
            body
        }).then((value: any) => {
            if (value.data) {
                const newRole = value.data.result
                setRoles(
                    roles.map((role) => {
                        if (role.id === id) {
                            return newRole
                        }

                        return role
                    })
                )

                callback()
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Оновлення ролі не вдалося')
            }
        })
    }
}
