import AddActionBtn from './AddActionBtn'

interface IAddActionBtnProps {
    taskId?: string
}

export default function ActionsTreeHeader({ taskId }: IAddActionBtnProps) {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <span style={{ fontWeight: 'bold' }}>Дії</span>
            <AddActionBtn taskId={taskId} />
        </div>
    )
}
