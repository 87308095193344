import { FileDoneOutlined } from '@ant-design/icons'
import { useContext } from 'react'
import { gameContext } from '../../../../contexts'

export default function OpenSidebarBtn() {
    const {
        isSidebarVisibleState: [isVisible, setIsVisible]
    } = useContext(gameContext)

    return <FileDoneOutlined className="open-sidebar-btn" onClick={() => setIsVisible(true)} />
}
