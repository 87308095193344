import { Divider, FormInstance, Select } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { constructorContext } from '../../../contexts'
import { IRoleInfo } from '../../../utils/interfaces/role'
import EditRoleInput from './EditRoleInput'
import NewRoleInput from './NewRoleInput'
import Role from './Role'

interface IRolesStateProps {
    form: FormInstance<any>
    value?: any
    onChange?: (value: any) => void
    required?: boolean
}

export default function RolesSelect({ form, value, onChange, required }: IRolesStateProps) {
    const [roles, setRoles] = useContext(constructorContext).rolesState
    const [newRoleTitle, setNewRoleTitle] = useState('')
    const [roleEdit, setRoleEdit] = useState<{ id: string; newTitle: string; title: string } | null>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [isRoleControlsHover, setIsRoleControlsHover] = useState(false)
    const [options, setOptions] = useState<
        | (
              | {
                    value: string
                    label: JSX.Element
                }
              | {
                    value: null
                    label: JSX.Element
                }
          )[]
        | []
    >([])

    const dropdownRender = (menu: React.ReactNode) => (
        <div>
            {menu}
            <Divider style={{ margin: 0 }} />
            <div style={{ padding: 8 }}>
                {roleEdit ? (
                    <EditRoleInput roleEditState={[roleEdit, setRoleEdit]} />
                ) : (
                    <NewRoleInput form={form} newRoleTitleState={[newRoleTitle, setNewRoleTitle]} />
                )}
            </div>
        </div>
    )

    useEffect(() => {
        setOptions(
            roles.map((role: IRoleInfo) => ({
                value: role.id,
                label: (
                    <Role
                        id={role.id}
                        title={role.title}
                        isSelectOpenState={[isOpen, setIsOpen]}
                        setIsRoleControlsHover={setIsRoleControlsHover}
                        selectedRole={value}
                        setRoleEdit={setRoleEdit}
                        form={form}
                    />
                )
            }))
        )
    }, [roles, isRoleControlsHover, isOpen, roleEdit])

    return (
        <Select
            options={
                required
                    ? options
                    : [
                          ...options,
                          {
                              value: null,
                              label: <div onClick={() => form.setFieldValue('roleId', undefined)}>Не вибрано</div>
                          }
                      ]
            }
            onDropdownVisibleChange={(open) => {
                setNewRoleTitle('')
                if (!isRoleControlsHover) {
                    setIsOpen(open)
                }
            }}
            open={isOpen}
            value={value}
            onChange={(value) => {
                if (!isRoleControlsHover && onChange) {
                    onChange(value)
                }
            }}
            dropdownRender={dropdownRender}
            placeholder="Роль"
        />
    )
}
