import { StarTwoTone } from '@ant-design/icons'
import { useContext } from 'react'
import { gameContext } from '../../../../contexts'
import { usePutUserProgress } from '../../../../hooks/store/users.api'

interface ISelectTaskBtnProps {
    taskId: string
}

export default function SelectTaskBtn({ taskId }: ISelectTaskBtnProps) {
    const putUserProgress = usePutUserProgress()

    const {
        isSidebarVisibleState: [isVisible, setIsVisible]
    } = useContext(gameContext)

    return (
        <StarTwoTone
            style={{ marginRight: '3px' }}
            onClick={() => {
                putUserProgress(taskId)
                setIsVisible(false)
            }}
        />
    )
}
