import { Header } from 'antd/lib/layout/layout'
import OpenSidebarBtn from './OpenSidebarBtn'
import UserDropdown from '../../../AdminLayout/AdminHeader/UserDropdown'
import { useToken } from '../../../../hooks/auth'
import { parseUser } from '../../../../utils/jwt'
import { useEffect, useState } from 'react'
import { VERSION } from '../../../../utils/constants'

export default function GameHeader() {
    const token = useToken()
    const [userState, setUserState] = useState(parseUser(token))
    const [userName, setUserName] = useState<string | null>(null)

    useEffect(() => {
        if (userState) {
            setUserName(userState.name)
        }
    }, [])

    return (
        <Header
            style={{
                padding: 0,
                background: 'white',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start'
            }}
        >
            <OpenSidebarBtn />
            <UserDropdown />
            <div style={{ marginLeft: '10px' }}>{userName}</div>
            <div style={{ position: 'absolute', right: '30px', display: 'flex' }}>
                <span style={{ fontSize: 20 }}>
                    <b>Правовий квест "Віртуальний суд"</b> {VERSION}
                </span>
            </div>
        </Header>
    )
}
