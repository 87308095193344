import { createContext } from 'react'
import useConstructorContextValue from './hooks/pages/Constructor/constructor'
import { useDashboardContextValue } from './hooks/pages/Dashboard/dashboard'
import useGameContextValue from './hooks/pages/Game/game'
import useLayoutContext from './hooks/pages/layout'
import { useUsersContextValue } from './hooks/pages/Users/users'

export const usersContext = createContext({} as ReturnType<typeof useUsersContextValue>)
export const constructorContext = createContext({} as ReturnType<typeof useConstructorContextValue>)
export const dashboardContext = createContext({} as ReturnType<typeof useDashboardContextValue>)
export const gameContext = createContext({} as ReturnType<typeof useGameContextValue>)
export const layoutContext = createContext<any>({} as ReturnType<typeof useLayoutContext>)
