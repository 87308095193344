import { useEffect, useState } from 'react'
import { useGlobalContextValue } from '../../global-context'
import { useFetchStories } from '../../store/stories.api'
import { useFetchUserProgress } from '../../store/users.api'
import { useCurrentTask } from './utils'

export default function useGameContextValue() {
    const globalContext = useGlobalContextValue()

    const [stories, setStories] = globalContext.storiesState

    const currentTask = useCurrentTask()

    const [roles] = globalContext.rolesState
    const [roleId, setRoleId] = useState<string | null>(null)
    useEffect(() => {
        if (currentTask?.roleId || !roleId) {
            setRoleId(currentTask?.roleId || roles[0]?.id || null)
        }
    }, [roles, currentTask])

    const locationIdState = useState<string | null>(null)
    const isSidebarVisibleState = useState(false)
    const eventState = useState<string | null>(null)

    useFetchStories((result) => {
        setStories(result)
    })

    useFetchUserProgress()

    return {
        ...globalContext,
        locationIdState,
        isSidebarVisibleState,
        eventState,
        roleIdState: [roleId, setRoleId] as [string | null, React.Dispatch<React.SetStateAction<string | null>>],
        currentTask
    }
}
