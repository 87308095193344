import { useContext, useEffect, useState } from 'react'
import { gameContext } from '../../../contexts'
import Action from './Action'
import { useAppSelector } from '../../../hooks/store'
import { useCurrentTaskProgress } from '../../../hooks/pages/Game/utils'
import { IUserProgressInfo } from '../../../utils/interfaces/user'
import { IActionInfo } from '../../../utils/interfaces/action'

export default function Actions() {
    const {
        actionsState: [actions, setActions],
        tasksState: [tasks, setTasks],
        eventState: [event, setEvent]
    } = useContext(gameContext)

    const userProgress = useAppSelector((state) => state.gameReducer.userProgress)

    const [actionIds, setActionIds] = useState<string[] | null>(null)
    const taskProgressActionIds = useCurrentTaskProgress()?.actionIds

    useEffect(() => {
        let nextActionIds: string[] | undefined

        if (taskProgressActionIds?.length) {
            const action = actions.find((action) => action.id === taskProgressActionIds.at(-1))
            nextActionIds = action?.actionIds
            setEvent(action?.event || null)
        } else {
            const firstTaskActionId = tasks.find(
                (task) => task.id === (userProgress as IUserProgressInfo).currentTaskId
            )?.actionIds[0]

            if (firstTaskActionId) {
                const firstTaskAction = actions.find((action) => action.id === firstTaskActionId)

                nextActionIds = firstTaskAction?.actionIds
                setEvent(firstTaskAction?.event || null)
            }
        }

        setActionIds(nextActionIds || null)
    }, [userProgress])

    const actionsById: { [actionId: string]: IActionInfo } = actions.reduce((acc, action) => {
        acc[action.id] = action
        return acc
    }, {} as { [actionId: string]: IActionInfo })

    return (
        <div className="actions-container">
            {actionIds?.map((actionId) => {
                const action = actionsById[actionId]
                if (!action) return null

                return <Action key={action.id} id={action.id} title={action.title} description={action.description} />
            })}
        </div>
    )
}
