import { Progress } from 'antd'
import { ITaskInfo } from '../../utils/interfaces/task'
import { ITaskProgress } from '../../utils/interfaces/task-progress'

interface ICurrentTaskProgressProps {
    currentTaskProgress: ITaskProgress
    currentTask: ITaskInfo
}

export default function CurrentTaskProgress({ currentTaskProgress, currentTask }: ICurrentTaskProgressProps) {
    const actionsLength = currentTask.actionIds.length - 1
    const passedDactionsLength = currentTaskProgress.actionIds.length - 1
    const percent = Math.round((passedDactionsLength * 100) / actionsLength)

    return (
        <Progress
            percent={percent}
            status={
                currentTaskProgress?.status === 'error'
                    ? 'exception'
                    : currentTaskProgress?.status === 'completed'
                    ? 'success'
                    : 'active'
            }
        />
    )
}
