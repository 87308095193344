import { MoreOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import DeleteStoryBtn from './DeleteStoryBtn'
import EditStoryBtn from './EditStoryBtn'

export default function StoryTabControls() {
    const menu = (
        <Menu
            items={[
                {
                    key: 1,
                    label: <EditStoryBtn />
                },
                {
                    key: 2,
                    label: <DeleteStoryBtn />
                }
            ]}
        />
    )

    return (
        <Dropdown overlay={menu} placement="topLeft">
            <MoreOutlined style={{ margin: '0 0 0 10px', padding: 0 }} />
        </Dropdown>
    )
}
