import React, { useContext } from 'react'
import { constructorContext } from '../../contexts'
import { IConstructorModals } from '../../hooks/pages/Constructor/constuctor-modal'
import ConstructorModal from './ConstructorModal'

export default function ConstructorModals() {
    const { useConstructorModals } = useContext(constructorContext)

    const constructorModals: IConstructorModals = useConstructorModals()

    return (
        <>
            {Object.keys(constructorModals).map((key) =>
                React.createElement(ConstructorModal, { id: key, ...constructorModals[key], key })
            )}
        </>
    )
}
