import { createApi } from '@reduxjs/toolkit/query/react'
import {
    IFetchTasksResponse,
    ITaskDeleteResponse,
    ITaskPostBody,
    ITaskPostResponse,
    ITaskPutBody,
    ITaskPutResponse
} from '../utils/interfaces/task'
import { baseQueryWithRefresh } from '../utils/store'

export const tasksApi = createApi({
    reducerPath: 'tasks/api',
    baseQuery: baseQueryWithRefresh,
    endpoints: (builder) => ({
        postTask: builder.mutation<ITaskPostResponse, { storyId: string | null; body: ITaskPostBody }>({
            query: ({ storyId, body }) => ({
                method: 'POST',
                url: '/api/private/tasks',
                body,
                params: {
                    storyId
                }
            })
        }),
        dublicateTask: builder.mutation<ITaskPostResponse, { storyId: string; oldTaskId: string }>({
            query: ({ storyId, oldTaskId }) => ({
                method: 'POST',
                url: `/api/private/tasks/dublicate`,
                params: {
                    oldTaskId,
                    storyId
                }
            })
        }),
        fetchTasks: builder.query<IFetchTasksResponse, void>({
            query: () => ({
                url: '/api/private/tasks',
                params: {
                    order: `timestamp,asc`
                }
            })
        }),
        putTask: builder.mutation<ITaskPutResponse, { id: string; body: ITaskPutBody }>({
            query: ({ id, body }) => ({
                method: 'PUT',
                url: `/api/private/tasks/${id}`,
                body
            })
        }),
        deleteTask: builder.mutation<ITaskDeleteResponse, { id: string }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `/api/private/tasks/${id}`
            })
        })
    })
})

export const {
    usePostTaskMutation,
    useFetchTasksQuery,
    usePutTaskMutation,
    useDeleteTaskMutation,
    useDublicateTaskMutation
} = tasksApi
