import { usePostNotificationMutation } from '../../store/notifications.api'
import { successNotification, errorNotification } from '../../utils/notifications'

export function usePostNotification() {
    const [postNotification] = usePostNotificationMutation()

    return (description: string) => {
        postNotification({ body: { description, type: 'bug' } }).then((value: any) => {
            if (value.data) {
                successNotification('Повідомлення успішно доставлено')
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Помилка відправки повідомлення')
            }
        })
    }
}
