import React, { SetStateAction, useContext, useEffect } from 'react'
import { constructorContext } from '../../contexts'
import {
    useDeleteLocationMutation,
    useFetchLocationsQuery,
    usePostLocationMutation,
    usePutLocationMutation
} from '../../store/locations.api'
import { getChanges } from '../../utils/components/Constructor/constructor-modal'
import { ILocationInfo, ILocationPostBody, ILocationPutBody } from '../../utils/interfaces/location'
import { errorNotification } from '../../utils/notifications'
import { useToken } from '../auth'

export function useFecthLocations(setLocations: React.Dispatch<SetStateAction<ILocationInfo[]>>) {
    const token = useToken()

    const fetchLocationsQuery = useFetchLocationsQuery(undefined, { skip: !token })

    useEffect(() => {
        if (fetchLocationsQuery.data?.result) {
            setLocations(fetchLocationsQuery.data.result)
        }
    }, [fetchLocationsQuery.data])
}

export function usePostLocation(callback?: () => void) {
    const [locations, setLocations] = useContext(constructorContext).locationsState

    const [postLocationMutation] = usePostLocationMutation()

    return (body: ILocationPostBody) => {
        postLocationMutation({
            body
        }).then((value: any) => {
            if (value.data) {
                const location: ILocationInfo = value?.data?.result
                setLocations([...locations, location])

                callback && callback()
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Додати локацію не вдалося')
            }
        })
    }
}

export function usePutLocation(
    initialValuesState?: [ILocationInfo, React.Dispatch<React.SetStateAction<ILocationInfo>>],
    callback?: () => void
) {
    const [locations, setLocations] = useContext(constructorContext).locationsState

    const [putLocationMutation] = usePutLocationMutation()

    return (body: ILocationPutBody) => {
        if (!initialValuesState) {
            throw new Error('No initialValuesState passed!')
        }

        const [initialValues, setInitialValues] = initialValuesState

        const putBody = getChanges(initialValues, body)
        if (!Object.keys(putBody).length) {
            return errorNotification('Не оновлено жодного поля')
        }

        putLocationMutation({ id: initialValues.id, body: putBody }).then((value: any) => {
            if (value.data) {
                const newLocation: ILocationInfo = value?.data?.result

                setLocations([
                    ...locations.map((location) => {
                        if (location.id === newLocation.id) {
                            return newLocation
                        }
                        return location
                    })
                ])
                setInitialValues(newLocation)

                callback && callback()
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Оновлення локації не вдалося')
            }
        })
    }
}

export function useDeleteLocation(id: string) {
    const [locations, setLocations] = useContext(constructorContext).locationsState

    const [deleteLocationMutation] = useDeleteLocationMutation()

    return () => {
        deleteLocationMutation({ id }).then((value: any) => {
            if (value.data) {
                const newLocations = [...locations.filter((location) => !(location.id === id))]
                setLocations(newLocations)
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Видалення локації не вдалося')
            }
        })
    }
}
