import Story from './Story'
import StoryTabs from './StoryTabs'

export default function Stories() {
    return (
        <div className="stories-container">
            <div className="stories-container-header">
                {/* <ActionsManagerBtn /> */}
                <StoryTabs />
            </div>
            <Story />
        </div>
    )
}
