import { StarOutlined } from '@ant-design/icons'
import { Card, Divider } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { useCurrentTask, useCurrentTaskProgress } from '../../hooks/pages/Game/utils'
import CurrentTaskProgress from './CurrentTaskProgress'

export default function CurrentTask() {
    const currentTask = useCurrentTask()
    const currentTaskProgress = useCurrentTaskProgress()

    return (
        <div className="current-task">
            <Card className="current-task-card">
                <Meta
                    title={
                        <div>
                            <StarOutlined style={{ fontSize: '20px', marginRight: '5px' }} />
                            <span>{currentTask?.title}</span>
                        </div>
                    }
                />
                <Divider />
                <div
                    style={{
                        color: 'black',
                        maxHeight: 300,
                        overflowY: 'scroll',
                        marginBottom: 10,
                        textAlign: 'justify',
                        paddingRight: 10
                    }}
                >
                    {currentTask?.description}
                </div>
                {currentTask && currentTaskProgress ? (
                    <CurrentTaskProgress currentTaskProgress={currentTaskProgress} currentTask={currentTask} />
                ) : null}
            </Card>
        </div>
    )
}
