import { DeleteOutlined } from '@ant-design/icons'
import { useDeleteRole } from '../../../hooks/store/roles.api'

interface IDeleteRoleBtnProps {
    id: string
    callback: () => void
}

export default function DeleteRoleBtn({ id, callback }: IDeleteRoleBtnProps) {
    const deleteRole = useDeleteRole()

    return (
        <DeleteOutlined
            style={{ marginLeft: 10 }}
            onClick={() => {
                deleteRole(id)
                callback()
            }}
        />
    )
}
