import { useState } from 'react'
import { useActionClick } from '../../../hooks/pages/Game/utils'
import { Tooltip } from 'antd'

interface IActionProps {
    title: string
    id: string
    description?: string
}

export default function Action({ title, id, description }: IActionProps) {
    const [isError, setIsError] = useState(false)

    const onClick = useActionClick(id, setIsError)

    const action = (
        <div
            onClick={() => onClick()}
            className="action-blok"
            style={{
                border: isError ? '1px solid red' : 'none',
                padding: '20px',
                fontSize: '20px'
            }}
        >
            {title}
        </div>
    )

    if (!description) {
        return action
    }

    return (
        <Tooltip overlayStyle={{ maxWidth: '30vw' }} placement="right" title={description}>
            {action}
        </Tooltip>
    )
}
