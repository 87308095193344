import { Tabs } from 'antd'
import { IStoryInfo } from '../../../utils/interfaces/story'
import StoryTabControls from './StoryTabControls'

type StoryTabProps = {
    tabKey: string
    story: IStoryInfo
    selectedStoryId: string | null
    key: string
}

export default function StoryTab({ tabKey, story, selectedStoryId, key }: StoryTabProps) {
    return (
        <Tabs.TabPane
            tabKey={tabKey}
            tab={
                <>
                    {story.title}
                    {story.id === selectedStoryId ? <StoryTabControls /> : null}
                </>
            }
            key={key}
        />
    )
}
