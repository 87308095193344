import { createApi } from '@reduxjs/toolkit/query/react'
import {
    IFetchStoriesResponse,
    IStoryDeleteResponse,
    IStoryPostBody,
    IStoryPostResponse,
    IStoryPutBody,
    IStoryPutResponse
} from '../utils/interfaces/story'
import { baseQueryWithRefresh } from '../utils/store'

export const storiesApi = createApi({
    reducerPath: 'stories/api',
    baseQuery: baseQueryWithRefresh,
    endpoints: (builder) => ({
        postStory: builder.mutation<IStoryPostResponse, { body: IStoryPostBody }>({
            query: ({ body }) => ({
                method: 'POST',
                url: '/api/private/stories',
                body
            })
        }),
        fetchStories: builder.query<IFetchStoriesResponse, void>({
            query: () => ({
                url: '/api/private/stories',
                params: {
                    order: `timestamp,asc`
                }
            })
        }),
        putStory: builder.mutation<IStoryPutResponse, { id: string; body: IStoryPutBody }>({
            query: ({ id, body }) => ({
                method: 'PUT',
                url: `/api/private/stories/${id}`,
                body
            })
        }),
        deleteStory: builder.mutation<IStoryDeleteResponse, { id: string }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `/api/private/stories/${id}`
            })
        })
    })
})

export const { usePostStoryMutation, useFetchStoriesQuery, usePutStoryMutation, useDeleteStoryMutation } = storiesApi
