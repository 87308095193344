import { FormInstance, Select } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { IRoleInfo } from '../../../utils/interfaces/role'
import { constructorContext } from '../../../contexts'
import { ILocationInfo } from '../../../utils/interfaces/location'

interface IRolesStateProps {
    form: FormInstance<any>
    value?: any
    onChange?: (value: any) => void
    required?: boolean
}

export default function LocationsSelect({ form, value, onChange, required }: IRolesStateProps) {
    const {
        locationsState: [locations, setLocations]
    } = useContext(constructorContext)
    const [options, setOptions] = useState<
        | (
              | {
                    value: string
                    label: string
                }
              | {
                    value: null
                    label: string
                }
          )[]
        | []
    >([])

    useEffect(() => {
        setOptions(
            locations.map((location: ILocationInfo) => ({
                value: location.id,
                label: location.title
            }))
        )
    }, [locations])

    return (
        <Select
            options={
                required
                    ? options
                    : [
                          ...options,
                          {
                              value: null,
                              label: <div onClick={() => form.setFieldValue('roleId', undefined)}>Не вибрано</div>
                          }
                      ]
            }
            value={value}
            onChange={(value) => {
                if (onChange) {
                    onChange(value)
                }
            }}
            placeholder="Локація"
        />
    )
}
