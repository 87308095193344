import DeleteLocationBtn from './DeleteLocationBtn'
import EditLocationBtn from './EditLocationBtn'

interface ILocationControlsProps {
    locationId: string
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
    setIsHover: React.Dispatch<React.SetStateAction<boolean>>
}

export default function LocationControls({ locationId, openState, setIsHover }: ILocationControlsProps) {
    return (
        <div className="location-controls">
            <EditLocationBtn id={locationId} />
            <DeleteLocationBtn setIsHover={setIsHover} openState={openState} id={locationId} />
        </div>
    )
}
