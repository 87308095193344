import { EditOutlined } from '@ant-design/icons'
import { useContext, useState } from 'react'
import { constructorContext } from '../../../contexts'

export default function EditLocationBtn({ id }: { id: string }) {
    const {
        locationsState: [locations],
        useConstructorModal
    } = useContext(constructorContext)

    const initialValuesState = useState(locations.find((location) => location.id === id))

    const createConstructorModal = useConstructorModal({
        name: 'location',
        action: 'edit',
        initialValuesState
    })

    return (
        <div
            onClick={() => {
                createConstructorModal()
            }}
            className="edit-location-btn"
        >
            <EditOutlined key="edit" style={{ fontSize: 20, color: 'white' }} />
        </div>
    )
}
