import { PlusOutlined } from '@ant-design/icons'
import { Tooltip, Button } from 'antd'
import { useContext } from 'react'
import { constructorContext } from '../../../contexts'

interface IAddStoryBtnProps {
    button?: boolean
}

export default function AddStoryBtn({ button }: IAddStoryBtnProps) {
    const { useConstructorModal } = useContext(constructorContext)
    const createConstructorModal = useConstructorModal({
        name: 'story',
        action: 'add'
    })

    return button ? (
        <Button type={'primary'} onClick={createConstructorModal}>
            Створити
        </Button>
    ) : (
        <Tooltip title="Додати історію">
            <PlusOutlined onClick={createConstructorModal} className="add-story-btn" style={{ fontSize: '25px' }} />
        </Tooltip>
    )
}
