import { Divider, Drawer } from 'antd'
import SelectRole from '../GameHeader/SelectRole'
import UserProgress from './UserProgress'

interface ISidebarProps {
    open: boolean
    setOpen: (arg: boolean) => void
}

export default function Sidebar({ open, setOpen }: ISidebarProps) {
    return (
        <Drawer title="Задачі" placement="left" onClose={() => setOpen(false)} open={open}>
            <SelectRole />
            <Divider />
            <UserProgress />
        </Drawer>
    )
}
