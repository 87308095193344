import { FormInstance, Input } from 'antd'
import React from 'react'
import AddRoleBtn from './AddRoleBtn'

interface INewRoleInputProps {
    form: FormInstance<any>
    newRoleTitleState: [string, React.Dispatch<React.SetStateAction<string>>]
}

export default function NewRoleInput({ form, newRoleTitleState: [newRoleTitle, setNewRoleTitle] }: INewRoleInputProps) {
    return (
        <Input
            value={newRoleTitle}
            onChange={(e) => setNewRoleTitle(e.target.value)}
            addonAfter={<AddRoleBtn form={form} newRoleTitleState={[newRoleTitle, setNewRoleTitle]} />}
            placeholder="Нова роль"
        />
    )
}
