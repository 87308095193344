import { ConstructorModalAction } from '../../../hooks/pages/Constructor/constuctor-modal'

export function getChanges(initialValues: any, values: any) {
    const updatedValues: any = {}

    for (const key in values) {
        if (values[key] !== initialValues[key]) {
            updatedValues[key] = values[key]
        }
    }

    return updatedValues
}

export function getOkText(action?: ConstructorModalAction) {
    if (action === 'add') {
        return 'Створити'
    } else if (action === 'edit') {
        return 'Редагувати'
    } else return null
}

export function getWidth(name: string) {
    const WIDE_WIDTH = 900

    switch (name) {
        case 'actions-manager':
            return WIDE_WIDTH
        case 'task':
            return WIDE_WIDTH
    }
}
