import { FormInstance } from 'antd'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import ActionForm from '../../../components/Constructor/Actions/ActionForm'
import ActionsManager, { ActionsManagerMode } from '../../../components/Constructor/Actions/ActionsManager'
import { IConstructorModalProps } from '../../../components/Constructor/ConstructorModal'
import LocationForm from '../../../components/Constructor/Locations/LocationForm'
import StoryForm from '../../../components/Constructor/Stories/StoryForm'
import TaskForm from '../../../components/Constructor/Tasks/TaskForm'
import { usePostAction, usePutAction } from '../../store/actions.api'
import { usePostLocation, usePutLocation } from '../../store/locations.api'
import { usePostStory, usePutStory } from '../../store/stories.api'
import { usePostTask, usePutTask } from '../../store/tasks.api'

export type ConstructorModalAction = 'add' | 'edit'
export type ConstructorModalName = 'story' | 'action' | 'actions-manager' | 'task' | 'location'

export interface IConstructorModal {
    name: ConstructorModalName
    initialValuesState?: [any, React.Dispatch<React.SetStateAction<any>>]
    action?: ConstructorModalAction
    queryParams?: { [key: string]: string | undefined }
}

export interface IConstructorModals {
    [key: string]: IConstructorModal
}

export interface IConstructorModalArgs {
    id?: string
    name: ConstructorModalName
    action?: ConstructorModalAction
    initialValuesState?: [any, React.Dispatch<React.SetStateAction<any>>]
    queryParams?: { [key: string]: string | undefined }
}

export function useConstructorModalHooks() {
    const [constructorModals, setConstructorModals] = useState<IConstructorModals>({})

    function useConstructorModals() {
        return constructorModals
    }

    function useConstructorModal({ id, name, action, initialValuesState, queryParams }: IConstructorModalArgs) {
        const [constructorModalId] = useState(id || uuidv4())
        const constructorModal: IConstructorModal = {
            name,
            action,
            initialValuesState,
            queryParams
        }

        return () => {
            if (!constructorModals[constructorModalId]) {
                setConstructorModals({ ...constructorModals, [constructorModalId]: constructorModal })
            }
        }
    }

    function useCleanConstructorModal(constructorModalId: string) {
        return () => {
            if (constructorModals[constructorModalId]) {
                const newConstructorModals: IConstructorModals = {}
                for (const key in constructorModals) {
                    if (key !== constructorModalId) {
                        newConstructorModals[key] = constructorModals[key]
                    }
                }
                setConstructorModals(newConstructorModals)
            }
        }
    }

    return {
        useConstructorModals,
        useConstructorModal,
        useCleanConstructorModal
    }
}

export function useFormComponent(form: FormInstance<any>, constructorModalProps: IConstructorModalProps) {
    const { name, initialValuesState } = constructorModalProps

    if (initialValuesState) {
        var [initialValues] = initialValuesState
    }

    switch (name) {
        case 'action':
            return <ActionForm form={form} initialValues={initialValues} />
        case 'actions-manager':
            return <ActionsManager mode={ActionsManagerMode.FREE} />
        case 'task':
            return <TaskForm form={form} initialValues={initialValues} />
        case 'story':
            return <StoryForm form={form} initialValues={initialValues} />
        case 'location':
            return <LocationForm form={form} initialValues={initialValues} />
    }
}

export function useConstructorModalQueries(props: IConstructorModalProps, cleanConstructoModal: () => void) {
    return {
        postStory: usePostStory(cleanConstructoModal),
        putStory: usePutStory(props.initialValuesState, cleanConstructoModal),

        postLocation: usePostLocation(cleanConstructoModal),
        putLocation: usePutLocation(props.initialValuesState, cleanConstructoModal),

        postTask: usePostTask(cleanConstructoModal),
        putTask: usePutTask(props.initialValuesState, cleanConstructoModal),

        postAction: usePostAction(
            {
                parentActionId: props.queryParams?.parentActionId
            },
            cleanConstructoModal
        ),
        putAction: usePutAction(props.initialValuesState, cleanConstructoModal)
    }
}
