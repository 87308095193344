import { useContext } from 'react'
import { gameContext } from '../../../contexts'

export default function Event() {
    const {
        eventState: [event, setEvent]
    } = useContext(gameContext)

    return event ? (
        <div className="event">
            <span style={{ fontWeight: 'bold' }}>Питання:</span> {event}
        </div>
    ) : null
}
