import { Switch } from 'antd'
import { ISettings } from '../../utils/interfaces/settings'

export default function Setting({
    settingsState,
    title,
    setting
}: {
    settingsState: [ISettings, React.Dispatch<React.SetStateAction<ISettings | null>>]
    title: string
    setting: keyof ISettings
}) {
    const [settings, setSettings] = settingsState

    return (
        <div style={{ margin: '10px 0' }}>
            <span style={{ marginRight: '10px' }}>{title}</span>
            <Switch
                checked={settings[setting]}
                onChange={(checked) => setSettings({ ...settings, [setting]: checked })}
            />
        </div>
    )
}
