import { Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import defaultLocationBackground from '../../../assets/historyLogo.svg'
import { ILocationInfo } from '../../../utils/interfaces/location'
import LocationControls from './LocationControls'

export default function Location({ location }: { location: ILocationInfo }) {
    const [isHover, setIsHover] = useState(false)
    const [src, setSrc] = useState<string | null>(null)
    const [popVisible, setPopVisible] = useState(false)

    useEffect(() => {
        setSrc(null)

        if (!location.background) {
            return
        }

        const image = new Image()
        image.addEventListener('load', () => {
            setSrc(location.background as string)
        })
        image.src = location.background
    }, [location.background])

    return (
        <Tooltip title={location.title} placement="left" color={'#5f5d5dbb'}>
            <div
                className="location"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => {
                    if (!popVisible) {
                        setIsHover(false)
                    }
                }}
                style={{ backgroundImage: `url(${src || defaultLocationBackground})` }}
            >
                {isHover && (
                    <LocationControls
                        openState={[popVisible, setPopVisible]}
                        setIsHover={setIsHover}
                        locationId={location.id}
                    />
                )}
            </div>
        </Tooltip>
    )
}
