import { StarOutlined, PauseCircleOutlined, WarningOutlined, CheckSquareOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { ITaskInfo } from '../../../../utils/interfaces/task'
import SelectTaskBtn from './SelectTaskBtn'
import PauseTaskBtn from './PauseTaskBtn'
import { TaskStatus } from '../../../../utils/interfaces/task-progress'
import DeleteTaskProgressBtn from './DeleteTaskProgressBtn'
import { useGetTaskProgressIdsObject } from '../../../../hooks/pages/Game/utils'

interface ITreeDataTaskTitleProps {
    taskStatus?: TaskStatus
    currentTaskId: string | null
    task: ITaskInfo
}

export default function TreeDataTaskTitle({ taskStatus, currentTaskId, task }: ITreeDataTaskTitleProps) {
    const [isHover, setIsHover] = useState(false)

    const { [task.id]: taskProgressId } = useGetTaskProgressIdsObject()

    return (
        <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            {getTaskStatus({
                status: taskStatus,
                isCurrentTask: task.id === currentTaskId,
                isHover,
                taskId: task.id
            })}
            {taskStatus && isHover && <DeleteTaskProgressBtn id={taskProgressId} />}
            {task.title}
        </div>
    )
}

function getTaskStatus({
    status,
    isCurrentTask,
    isHover,
    taskId
}: {
    status?: TaskStatus
    isCurrentTask: boolean
    isHover: boolean
    taskId: string
}) {
    if (isCurrentTask) {
        return isHover ? <PauseTaskBtn /> : <StarOutlined style={{ color: 'orange', marginRight: '3px' }} />
    }

    switch (status) {
        case 'process':
            return isHover ? <SelectTaskBtn taskId={taskId} /> : <PauseCircleOutlined style={{ marginRight: '3px' }} />
        case 'error':
            return isHover ? (
                <SelectTaskBtn taskId={taskId} />
            ) : (
                <WarningOutlined style={{ color: 'red', marginRight: '3px' }} />
            )
        case 'completed':
            return <CheckSquareOutlined style={{ color: 'green', marginRight: '3px' }} />
        case undefined:
            return isHover ? <SelectTaskBtn taskId={taskId} /> : null
    }
}
