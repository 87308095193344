import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { useDeleteAction } from '../../../hooks/store/actions.api'

interface IDeleteActionBtnProps {
    id: string
}

export default function DeleteActionBtn({ id }: IDeleteActionBtnProps) {
    const deleteAction = useDeleteAction(id)

    return (
        <Popconfirm title="Are you sure to delete action?" onConfirm={deleteAction} okText="Delete" cancelText="Cancel">
            <div style={{ padding: 12 }}>
                <DeleteOutlined
                    style={{
                        color: '#8C8C8C',
                        fontSize: 17
                    }}
                />
            </div>
        </Popconfirm>
    )
}
