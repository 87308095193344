import { CopyOutlined } from '@ant-design/icons'
import { useDublicateTask } from '../../../hooks/store/tasks.api'

interface IDublicateTaskBtnProps {
    storyId: string
    oldTaskId: string
}

export default function DublicateTaskBtn({ storyId, oldTaskId }: IDublicateTaskBtnProps) {
    const dublicateTask = useDublicateTask(storyId, oldTaskId)

    return (
        <CopyOutlined
            onClick={() => {
                dublicateTask()
            }}
        />
    )
}
