import { combineReducers, configureStore } from '@reduxjs/toolkit'
import appReducer from './app.reducer'
import adminReducer from './admin.reducer'
import gameReducer from './game.reducer'
import { privateUsersApi, publicUsersApi } from './users.api'
import { authApi } from './auth.api'
import { settingsApi } from './settings.api'
import { storiesApi } from './stories.api'
import { rolesApi } from './roles.api'
import { locationsApi } from './locations.api'
import { actionsApi } from './actions.api'
import { tasksApi } from './tasks.api'
import { taskProgressesApi } from './task-progresses.api'
import { notificationsApi } from './notifications.api'
import { statisticsApi } from './statistics.api'

const rootReducer = combineReducers({
    appReducer,
    adminReducer,
    gameReducer,
    [authApi.reducerPath]: authApi.reducer,
    [privateUsersApi.reducerPath]: privateUsersApi.reducer,
    [publicUsersApi.reducerPath]: publicUsersApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [storiesApi.reducerPath]: storiesApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [locationsApi.reducerPath]: locationsApi.reducer,
    [actionsApi.reducerPath]: actionsApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer,
    [taskProgressesApi.reducerPath]: taskProgressesApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [statisticsApi.reducerPath]: statisticsApi.reducer
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware().concat(
                authApi.middleware,
                privateUsersApi.middleware,
                publicUsersApi.middleware,
                settingsApi.middleware,
                storiesApi.middleware,
                rolesApi.middleware,
                locationsApi.middleware,
                actionsApi.middleware,
                tasksApi.middleware,
                taskProgressesApi.middleware,
                notificationsApi.middleware,
                statisticsApi.middleware
            )
        }
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
