import { useState } from 'react'
import { IActionInfo } from '../utils/interfaces/action'
import { ILocationInfo } from '../utils/interfaces/location'
import { IRoleInfo } from '../utils/interfaces/role'
import { IStoryInfo } from '../utils/interfaces/story'
import { ITaskInfo } from '../utils/interfaces/task'
import { useFetchActions } from './store/actions.api'
import { useFecthLocations } from './store/locations.api'
import { useFetchRoles } from './store/roles.api'
import { useFetchTasks } from './store/tasks.api'

export function useGlobalContextValue() {
    const storiesState = useState<IStoryInfo[]>([])
    const tasksState = useState<ITaskInfo[]>([])
    const locationsState = useState<ILocationInfo[]>([])
    const rolesState = useState<IRoleInfo[]>([])
    const actionsState = useState<IActionInfo[]>([])

    useFetchTasks(tasksState[1])
    useFecthLocations(locationsState[1])
    useFetchRoles(rolesState[1])
    useFetchActions(actionsState[1])

    return {
        storiesState,
        tasksState,
        locationsState,
        rolesState,
        actionsState
    }
}
