import { EditOutlined } from '@ant-design/icons'
import { useContext, useState } from 'react'
import { constructorContext } from '../../../contexts'

interface IEditTaskBtnProps {
    id: string
}

export default function EditTaskBtn({ id }: IEditTaskBtnProps) {
    const {
        tasksState: [tasks],
        bufferActionIdsState: [bufferActionIds, setBufferActionIds],
        useConstructorModal
    } = useContext(constructorContext)

    const initialValuesState = useState(tasks.find((task) => task.id === id))

    const createConstructorModal = useConstructorModal({
        name: 'task',
        action: 'edit',
        initialValuesState
    })

    return (
        <EditOutlined
            onClick={() => {
                setBufferActionIds(initialValuesState[0]?.actionIds || [])
                createConstructorModal()
            }}
        />
    )
}
