import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../store'
import { gameContext } from '../../../contexts'
import { useAppDispatch } from '../../store/index'
import { TaskStatus } from '../../../utils/interfaces/task-progress'
import { errorNotification } from '../../../utils/notifications'
import { gameSlice } from '../../../store/game.reducer'
import { useGetCheckAction } from '../../store/actions.api'
import { ITaskInfo } from '../../../utils/interfaces/task'
import { useGlobalContextValue } from '../../global-context'

export function useCurrentTaskProgress() {
    const userProgress = useAppSelector((state) => state.gameReducer.userProgress)

    return userProgress?.currentTaskId
        ? userProgress.taskProgresses.find((taskProgress) => taskProgress.taskId === userProgress.currentTaskId)
        : null
}

export function useCurrentTask(): ITaskInfo | undefined | null {
    const { tasksState } = useGlobalContextValue()

    const [tasks, setTasks] = tasksState

    const currentTaskId = useAppSelector((state) => state.gameReducer.userProgress?.currentTaskId)
    // TODO: Refactor
    return useMemo(
        () => (currentTaskId ? tasks.find((task) => currentTaskId === task.id) : null),
        [tasks, currentTaskId]
    )
}

export function useActionClick(id: string, setIsError: React.Dispatch<React.SetStateAction<boolean>>) {
    const dispatch = useAppDispatch()
    const userProgress = useAppSelector((state) => state.gameReducer.userProgress)

    const {
        tasksState: [tasks, setTasks]
    } = useContext(gameContext)

    const taskActionIds = useCurrentTask()?.actionIds
    const currentTaskProgress = useCurrentTaskProgress()
    const taskProgressActionIds = currentTaskProgress?.actionIds

    const callback = useCallback(() => {
        if (!(userProgress && taskProgressActionIds && taskActionIds)) {
            return
        }

        let status: TaskStatus = 'process'
        setIsError(false)

        if (taskActionIds[taskProgressActionIds.length] !== id) {
            status = 'error'
            errorNotification('Невірна дія!', 'Помилка')
            setIsError(true)
        } else if (taskActionIds.length === taskProgressActionIds.length + 1) {
            status = 'completed'
        }

        const newUserProgress = {
            ...userProgress,
            taskProgresses: userProgress.taskProgresses.map((taskProgress) => {
                if (taskProgress.taskId === userProgress.currentTaskId) {
                    const newTaskProgress = {
                        ...taskProgress,
                        status
                    }

                    if (status === 'error') {
                        newTaskProgress.errors++
                    } else {
                        newTaskProgress.actionIds = [...taskProgress.actionIds, id]
                    }

                    return newTaskProgress
                }

                return taskProgress
            })
        }
        dispatch(gameSlice.actions.setUserProgress(newUserProgress))
    }, [userProgress, tasks, taskActionIds, taskProgressActionIds])

    return useGetCheckAction(id, callback)
}

export function useGetTaskProgressIdsObject() {
    const userProgress = useAppSelector((state) => state.gameReducer.userProgress)
    const [state, setState] = useState<{ [key: string]: string }>({})

    useEffect(() => {
        if (!userProgress) {
            return
        }

        const newState: { [key: string]: string } = {}

        for (const taskProgress of userProgress.taskProgresses) {
            newState[taskProgress.taskId] = taskProgress.id
        }
        setState(newState)
    }, [userProgress?.taskProgresses])

    return state
}
