import { useState } from 'react'
import { useGlobalContextValue } from '../../global-context'
import { useConstructorModalHooks } from './constuctor-modal'
import { useFetchStories } from '../../store/stories.api'

export default function useConstructorContextValue() {
    const globalContext = useGlobalContextValue()

    const [stories, setStories] = globalContext.storiesState
    const [selectedStoryId, setSelectedStoryId] = useState<string | null>(stories[0]?.id || null)

    const bufferActionIdsState = useState<string[]>([])

    useFetchStories((result) => {
        setStories(result)
        setSelectedStoryId(result[0].id)
    })

    return {
        ...globalContext,
        ...useConstructorModalHooks(),
        selectedStoryIdState: [selectedStoryId, setSelectedStoryId] as [
            string | null,
            React.Dispatch<React.SetStateAction<string | null>>
        ],
        bufferActionIdsState
    }
}
