import { Card } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { useContext, useEffect, useState } from 'react'
import { ITaskInfo } from '../../../utils/interfaces/task'
import { constructorContext } from '../../../contexts'
import EditTaskBtn from './EditTaskBtn'
import DeleteTaskBtn from './DeleteTaskBtn'
import DublicateTaskBtn from './DublicateTaskBtn'

export default function Task({ task }: { task: ITaskInfo }) {
    const {
        rolesState: [roles, setRoles],
        selectedStoryIdState: [selectedStoryId, setSelectedStoryId]
    } = useContext(constructorContext)

    const [role, setRole] = useState<string | null>(null)

    useEffect(() => {
        setRole(roles.find((role) => role.id === task.roleId)?.title || null)
    }, [task.roleId, roles])

    return (
        <Card
            key={task.id}
            className="task-card"
            actions={[
                <EditTaskBtn id={task.id} />,
                <DublicateTaskBtn storyId={selectedStoryId as string} oldTaskId={task.id} />,
                <DeleteTaskBtn id={task.id} />
            ]}
        >
            <Meta title={task.title} description={role} />
        </Card>
    )
}
