import { FormInstance } from 'antd'
import { useContext } from 'react'
import DeleteRoleBtn from './DeleteRoleBtn'
import EditRoleBtn from './EditRoleBtn'
import { constructorContext } from '../../../contexts'

interface IRoleControlsProps {
    id: string
    title: string
    selectedRole: string
    form: FormInstance<any>
    setIsOpenSelect: React.Dispatch<React.SetStateAction<boolean>>
    setIsRoleControlsHover: React.Dispatch<React.SetStateAction<boolean>>
    setRoleEdit: React.Dispatch<React.SetStateAction<{ id: string; newTitle: string; title: string } | null>>
}

export default function RoleControls({
    id,
    title,
    selectedRole,
    form,
    setIsOpenSelect,
    setIsRoleControlsHover,
    setRoleEdit
}: IRoleControlsProps) {
    const {
        rolesState: [roles, setRoles]
    } = useContext(constructorContext)

    return (
        <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onClick={() => setIsOpenSelect(true)}
            onMouseEnter={() => setIsRoleControlsHover(true)}
            onMouseLeave={() => setIsRoleControlsHover(false)}
        >
            <EditRoleBtn id={id} title={title} setRoleEdit={setRoleEdit} />
            <DeleteRoleBtn
                id={id}
                callback={() => {
                    setIsRoleControlsHover(false)
                    if (id === selectedRole) {
                        form.setFieldValue('roleId', roles.length ? roles[0].id : undefined)
                    }
                }}
            />
        </div>
    )
}
