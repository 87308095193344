import { FormInstance } from 'antd'
import { useState } from 'react'
import RoleControls from './RoleControls'

interface IRoleProps {
    id: string
    title: string
    selectedRole: string
    isSelectOpenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
    setIsRoleControlsHover: React.Dispatch<React.SetStateAction<boolean>>
    setRoleEdit: React.Dispatch<React.SetStateAction<{ id: string; newTitle: string; title: string } | null>>
    form: FormInstance<any>
}

export default function Role({
    id,
    title,
    selectedRole,
    isSelectOpenState,
    setIsRoleControlsHover,
    setRoleEdit,
    form
}: IRoleProps) {
    const [isHover, setIsHover] = useState(false)

    const [isSelectOpen, setIsSelectOpen] = isSelectOpenState

    return (
        <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {title}
            {isHover && isSelectOpen && (
                <RoleControls
                    id={id}
                    title={title}
                    selectedRole={selectedRole}
                    form={form}
                    setIsOpenSelect={setIsSelectOpen}
                    setIsRoleControlsHover={setIsRoleControlsHover}
                    setRoleEdit={setRoleEdit}
                />
            )}
        </div>
    )
}
