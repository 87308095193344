import { Divider, Input } from 'antd'
import { usePutRole } from '../../../hooks/store/roles.api'
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { errorNotification } from '../../../utils/notifications'

interface IEditRoleInputProps {
    roleEditState: [
        { id: string; newTitle: string; title: string } | null,
        React.Dispatch<React.SetStateAction<{ id: string; newTitle: string; title: string } | null>>
    ]
}

export default function EditRoleInput({ roleEditState }: IEditRoleInputProps) {
    const [roleEdit, setRoleEdit] = roleEditState

    const putRole = usePutRole(() => setRoleEdit(null))

    if (!roleEdit) {
        return null
    }

    return (
        <Input
            value={roleEdit.newTitle}
            onChange={(e) => setRoleEdit({ ...roleEdit, newTitle: e.target.value })}
            addonAfter={
                <>
                    <EditOutlined
                        onClick={() => {
                            if (roleEdit.newTitle !== roleEdit.title) {
                                putRole(roleEdit.id, { title: roleEdit.newTitle })
                            } else {
                                errorNotification('Поле не оновлено')
                            }
                        }}
                    />
                    <Divider type={'vertical'} style={{ padding: 0 }} />
                    <CloseOutlined
                        onClick={() => {
                            setRoleEdit(null)
                        }}
                    />
                </>
            }
        />
    )
}
