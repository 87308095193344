import { useContext, useEffect, useState } from 'react'
import { constructorContext } from '../../../contexts'
import { ITaskInfo } from '../../../utils/interfaces/task'
import AddTaskBtn from './AddTaskBtn'
import Task from './Task'

export default function Tasks() {
    const {
        tasksState: [tasks, setTasks],
        selectedStoryIdState: [selectedStoryId, setSelectedStoryId],
        storiesState: [stories, setStories]
    } = useContext(constructorContext)

    const [storyTasks, setStoryTasks] = useState<ITaskInfo[]>([])

    useEffect(() => {
        if (!stories.length) {
            return
        }

        const taskIds = stories.find((story) => story.id === selectedStoryId)?.taskIds

        if (taskIds) {
            setStoryTasks(tasks.filter((task) => taskIds.includes(task.id)))
        }
    }, [tasks, selectedStoryId])

    return (
        <div className="tasks-container">
            {storyTasks.map((task: ITaskInfo) => (
                <Task task={task} key={task.id} />
            ))}
            <AddTaskBtn />
        </div>
    )
}
