import { useEffect } from 'react'
import { BugOutlined } from '@ant-design/icons'
import { Form, Modal } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useForm } from 'antd/lib/form/Form'
import { usePostNotification } from '../../../hooks/store/notifications.api'

interface IReportBugModalProps {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ReportBugModal({ open, setOpen }: IReportBugModalProps) {
    const [form] = useForm()

    const postNotification = usePostNotification()

    const onOk = () => {
        form.validateFields().then((body) => {
            postNotification(body.description)
        })

        setOpen(false)
    }

    return (
        <Modal
            title={
                <div>
                    <BugOutlined style={{ marginRight: 7, fontSize: 20 }} />
                    Повідомити про помилку
                </div>
            }
            open={open}
            onOk={onOk}
            okText="Надіслати"
            onCancel={() => {
                form.resetFields()
                setOpen(false)
            }}
            cancelText="Відмінити"
        >
            <Form form={form}>
                <Form.Item name="description">
                    <TextArea placeholder="Опис" rows={8} />
                </Form.Item>
            </Form>
        </Modal>
    )
}
