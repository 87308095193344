import { useContext } from 'react'
import {
    AimOutlined,
    CheckSquareOutlined,
    ClockCircleOutlined,
    EnvironmentOutlined,
    QuestionOutlined,
    SafetyCertificateOutlined,
    StopOutlined,
    TeamOutlined,
    ThunderboltOutlined,
    UserOutlined
} from '@ant-design/icons'
import moment from 'moment'
import DashboardCol from '../../components/Dashboard/DashboardCol'
import DashboardRow from '../../components/Dashboard/DashboardRow'
import { dashboardContext } from '../../contexts'

export default function DashboardRows() {
    const [statistics, setStatistics] = useContext(dashboardContext).statisticsState

    return statistics ? (
        <>
            <DashboardRow>
                <DashboardCol
                    title="Користувачі"
                    value={statistics.users.user}
                    icon={<UserOutlined style={{ color: 'blue' }} />}
                />
                <DashboardCol
                    title="Адміни"
                    value={statistics.users.admin}
                    icon={<SafetyCertificateOutlined style={{ color: 'green' }} />}
                />
                <DashboardCol
                    title="Непідтверджені користувачі"
                    value={statistics.users.unconfirmed}
                    icon={<QuestionOutlined />}
                />
                <DashboardCol
                    title="Заборонені користувачі"
                    value={statistics.users.banned}
                    icon={<StopOutlined style={{ color: 'red' }} />}
                />
            </DashboardRow>
            <DashboardRow>
                <DashboardCol title="Історії" value={statistics.storiesTotal} icon={<ThunderboltOutlined />} />
                <DashboardCol title="Завдання" value={statistics.tasksTotal} icon={<CheckSquareOutlined />} />
                <DashboardCol title="Локації" value={statistics.locationsTotal} icon={<EnvironmentOutlined />} />
                <DashboardCol title="Дії" value={statistics.actionsTotal} icon={<AimOutlined />} />
                <DashboardCol title="Ролі" value={statistics.rolesTotal} icon={<TeamOutlined />} />
            </DashboardRow>
            <DashboardRow>
                <DashboardCol
                    title="Час початку"
                    value={moment(statistics.startTimestamp).format('DD.MM.YYYY HH:mm:ss')}
                    icon={<ClockCircleOutlined />}
                />
            </DashboardRow>
        </>
    ) : null
}
