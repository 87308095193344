import '../styles/Constructor.scss'
import { constructorContext } from '../contexts'
import Locations from '../components/Constructor/Locations/Locations'
import Stories from '../components/Constructor/Stories/Stories'
import useConstructorContextValue from '../hooks/pages/Constructor/constructor'
import ConstructorModals from '../components/Constructor/ConstructorModals'
import { useTitle } from '../hooks/pages/layout'

export default function Constructor() {
    useTitle('Конструктор')

    return (
        <constructorContext.Provider value={useConstructorContextValue()}>
            <div className="constructor-container">
                <Locations />
                <Stories />
                <ConstructorModals />
            </div>
        </constructorContext.Provider>
    )
}
