import { createApi } from '@reduxjs/toolkit/query/react'
import {
    IActionDeleteResponse,
    IActionPostBody,
    IActionPostResponse,
    IActionPutBody,
    IActionPutResponse,
    IFetchActionsResponse
} from '../utils/interfaces/action'
import { baseQueryWithRefresh } from '../utils/store'

export const actionsApi = createApi({
    reducerPath: 'actions/api',
    baseQuery: baseQueryWithRefresh,
    endpoints: (builder) => ({
        postAction: builder.mutation<
            IActionPostResponse,
            { params?: { [key: string]: string | undefined }; body: IActionPostBody }
        >({
            query: ({ params, body }) => ({
                method: 'POST',
                url: '/api/private/actions',
                body,
                params
            })
        }),
        getCheckAction: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                url: `/api/private/actions/${id}/check`,
                responseHandler: 'text'
            })
        }),
        fetchActions: builder.query<IFetchActionsResponse, void>({
            query: () => ({
                url: '/api/private/actions',
                params: {
                    order: `timestamp,asc`
                }
            })
        }),
        putAction: builder.mutation<IActionPutResponse, { id: string; body: IActionPutBody }>({
            query: ({ id, body }) => ({
                method: 'PUT',
                url: `/api/private/actions/${id}`,
                body
            })
        }),
        deleteAction: builder.mutation<IActionDeleteResponse, { id: string }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `/api/private/actions/${id}`
            })
        })
    })
})

export const {
    usePostActionMutation,
    useGetCheckActionMutation,
    useFetchActionsQuery,
    usePutActionMutation,
    useDeleteActionMutation
} = actionsApi
