import React, { useContext, useEffect } from 'react'
import { constructorContext } from '../../contexts'
import {
    useDeleteActionMutation,
    useFetchActionsQuery,
    useGetCheckActionMutation,
    usePostActionMutation,
    usePutActionMutation
} from '../../store/actions.api'
import { getChanges } from '../../utils/components/Constructor/constructor-modal'
import { IActionInfo, IActionPostBody, IActionPutBody } from '../../utils/interfaces/action'
import { errorNotification } from '../../utils/notifications'
import { useToken } from '../auth'

export function useGetCheckAction(id: string | null, callback: () => void) {
    const token = useToken()

    const [getCheckActionMutation] = useGetCheckActionMutation()

    return (internalId?: string) => {
        if (!token) {
            return
        }

        getCheckActionMutation({ id: id || (internalId as string) }).then((value: any) => {
            if (
                value.data ||
                ((value.error as any)?.status === 403 &&
                    (value.error as any)?.data &&
                    JSON.parse((value.error as any)?.data).error === 'Неправильна дія')
            ) {
                callback()
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Перевірити дію не вдалося')
            }
        })
    }
}

export function useFetchActions(callback: (result: IActionInfo[]) => void) {
    const token = useToken()

    const fetchActionsQuery = useFetchActionsQuery(undefined, { skip: !token })

    useEffect(() => {
        if (fetchActionsQuery.data?.result) {
            callback(fetchActionsQuery.data.result)
        }
    }, [fetchActionsQuery.data])
}

export function usePostAction(params: { [key: string]: string | undefined }, callback?: () => void) {
    const [actions, setActions] = useContext(constructorContext).actionsState
    const [postActionMutation] = usePostActionMutation()

    return (body: IActionPostBody) => {
        postActionMutation({
            params,
            body
        }).then((value: any) => {
            if (value.data) {
                const newAction: IActionInfo = value?.data?.result

                const newActions = params.parentActionId
                    ? actions.map((action) => {
                          if (action.id === params.parentActionId) {
                              return { ...action, actionIds: [...action.actionIds, newAction.id] }
                          }

                          return action
                      })
                    : actions

                setActions([...newActions, newAction])

                callback && callback()
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Додати дію не вдалося')
            }
        })
    }
}

export function usePutAction(
    initialValuesState?: [IActionInfo, React.Dispatch<React.SetStateAction<IActionInfo>>],
    callback?: () => void
) {
    const [actions, setActions] = useContext(constructorContext).actionsState
    const [putActionMutation] = usePutActionMutation()

    if (initialValuesState) {
        var [initialValues, setInitialValues] = initialValuesState
    }

    return (body: IActionPutBody, id?: string) => {
        if (!initialValuesState && !id) {
            return new Error('No initialValuesState or id passed!')
        }

        const putBody = initialValues ? getChanges(initialValues, body) : body
        if (!Object.keys(putBody).length) {
            return errorNotification('Не оновлено жодного поля')
        }

        putActionMutation({
            id: id || initialValues.id,
            body: putBody
        }).then((value: any) => {
            if (value.data) {
                const newAction: IActionInfo = value?.data?.result
                setActions(
                    actions.map((action) => {
                        if (action.id === newAction.id) {
                            return newAction
                        }
                        return action
                    })
                )
                // TODO: Refactor: Ensure it is required to set initial values after contructor modal closing?

                if (setInitialValues) {
                    setInitialValues(newAction)
                }

                callback && callback()
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Додати дію не вдалося')
            }
        })
    }
}

export function useDeleteAction(id: string) {
    const {
        actionsState: [actions, setActions],
        tasksState: [tasks, setTasks]
    } = useContext(constructorContext)
    const [deleteActionMutation] = useDeleteActionMutation()

    return () => {
        deleteActionMutation({ id }).then((value: any) => {
            if (value.data) {
                const deletedActionIds = value.data.result
                const taskId = actions.find((action) => action.id === id)?.taskId as string

                const newActions = [...actions.filter((action) => !deletedActionIds.includes(action.id))]
                setActions(newActions)
                setTasks(
                    tasks.map((task) => {
                        if (task.id === taskId) {
                            const newTaskActionIds: string[] = []

                            for (const id of task.actionIds) {
                                if (deletedActionIds.includes(id)) {
                                    break
                                }

                                newTaskActionIds.push(id)
                            }

                            return { ...task, actionIds: newTaskActionIds }
                        }

                        return task
                    })
                )
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Видалити дію не вдалося')
            }
        })
    }
}
