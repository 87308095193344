import { UserStatus } from '../../interfaces/user'

export function getTextForStatus(status: UserStatus) {
    switch (status) {
        case 'unconfirmed':
            return 'не підтверджено'
        case 'banned':
            return 'заблоковано'
    }
}
