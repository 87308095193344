import { CopyOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { successNotification } from '../../../utils/notifications'

interface ICopyActionBtnProps {
    id: string
}

export default function CopyActionBtn({ id }: ICopyActionBtnProps) {
    return (
        <Tooltip
            overlayStyle={{
                maxWidth: '400px'
            }}
            placement="right"
            title={id}
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
        >
            <div
                style={{ padding: 12 }}
                onClick={() => {
                    navigator.clipboard.writeText(id)
                    successNotification('ІD дії скопійовано в буфер обміну!')
                }}
            >
                <CopyOutlined
                    style={{
                        color: '#8C8C8C',
                        fontSize: 17
                    }}
                />
            </div>
        </Tooltip>
    )
}
