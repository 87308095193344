import { Form, FormInstance, Input } from 'antd'
import { useState } from 'react'
import { IStoryInfo, IStoryPostBody, IStoryPutBody } from '../../../utils/interfaces/story'
import { validationRules } from '../../../utils/validation'

type StoryFormProps = {
    form: FormInstance<IStoryPutBody | IStoryPostBody>
    initialValues?: IStoryInfo
}

export default function StoryForm({ form, initialValues }: StoryFormProps) {
    const [required] = useState(initialValues ? [] : [validationRules.REQUIRED()])

    return (
        <Form form={form} initialValues={initialValues}>
            <Form.Item name="title" rules={[...required]}>
                <Input placeholder="Назва" />
            </Form.Item>
            <Form.Item name="description">
                <Input.TextArea placeholder="Опис" rows={4} />
            </Form.Item>
        </Form>
    )
}
