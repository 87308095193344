import { useState, useEffect } from 'react'
import { useAppSelector } from '../../hooks/store'
import { TaskStatus } from '../../utils/interfaces/task-progress'
import { CheckSquareOutlined, StarOutlined, WarningOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

interface ITotal {
    completed: number
    error: number
    process: number
}

export default function Statistics() {
    const taskProgresses = useAppSelector((state) => state.gameReducer.userProgress?.taskProgresses)

    const [total, setTotal] = useState<ITotal | null>(null)

    useEffect(() => {
        setTotal(
            taskProgresses?.reduce(
                (prev, next) => {
                    return {
                        completed: next.status === 'completed' ? prev.completed + 1 : prev.completed,
                        error: prev.error + next.errors,
                        process: next.status === 'process' ? prev.process + 1 : prev.process
                    }
                },
                { completed: 0, error: 0, process: 0 }
            ) || null
        )
    }, [taskProgresses])

    if (!total) {
        return null
    }

    return (
        <div style={{ display: 'flex', fontSize: 70, justifyContent: 'center', alignItems: 'center', height: '80%' }}>
            {Object.keys(total).map((stat) => (
                <Tooltip title={getTooltipTitleByStatus(stat as TaskStatus)} key={stat}>
                    <div style={{ marginRight: '10vw' }}>
                        {getIconByStatus(stat as TaskStatus)} {total[stat as TaskStatus]}
                    </div>
                </Tooltip>
            ))}
        </div>
    )
}

function getIconByStatus(status: TaskStatus) {
    switch (status) {
        case 'completed':
            return <CheckSquareOutlined style={{ color: 'green' }} />
        case 'error':
            return <WarningOutlined style={{ color: 'red' }} />
        case 'process':
            return <StarOutlined />
    }
}

function getTooltipTitleByStatus(status: TaskStatus) {
    switch (status) {
        case 'completed':
            return 'Кількість завершених завдань'
        case 'error':
            return 'Загальна кількість помилок з усіх завдань'
        case 'process':
            return 'Кількість незавершених завдань'
    }
}
