import { PlusOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd'
import { usePostRole } from '../../../hooks/store/roles.api'

interface IAddRoleBtnProps {
    form: FormInstance<any>
    newRoleTitleState: [string, React.Dispatch<React.SetStateAction<string>>]
}

export default function AddRoleBtn({ form, newRoleTitleState: [newRoleTitle, setNewRoleTitle] }: IAddRoleBtnProps) {
    const postRole = usePostRole((newRoleId: string) => {
        setNewRoleTitle('')
        form.setFieldValue('roleId', newRoleId)
    })

    return <PlusOutlined onClick={() => postRole(newRoleTitle)} />
}
