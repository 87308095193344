import { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useContext } from 'react'
import { constructorContext } from '../../../contexts'

export default function AddLocationBtn() {
    const { useConstructorModal } = useContext(constructorContext)

    const createConstructorModal = useConstructorModal({
        name: 'location',
        action: 'add'
    })

    return (
        <div className="add-location-btn">
            <Tooltip title="Додати локацію">
                <Button
                    className="add-location-btn-icon"
                    shape="circle"
                    icon={<PlusOutlined style={{ fontSize: '30px' }} />}
                    onClick={createConstructorModal}
                />
            </Tooltip>
        </div>
    )
}
