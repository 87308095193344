import { useContext, useState } from 'react'
import { Modal } from 'antd'
import {
    IConstructorModal,
    useConstructorModalQueries,
    useFormComponent
} from '../../hooks/pages/Constructor/constuctor-modal'
import ConstructorModalTitle from './ConstructorModalTitle'
import { useForm } from 'antd/lib/form/Form'
import { constructorContext } from '../../contexts'
import { getOkText, getWidth } from '../../utils/components/Constructor/constructor-modal'
import { WarningOutlined } from '@ant-design/icons'

export interface IConstructorModalProps extends IConstructorModal {
    id: string
}

export default function ConstructorModal(props: IConstructorModalProps) {
    const {
        useCleanConstructorModal,
        bufferActionIdsState: [bufferActionIds, setBufferActionIds]
    } = useContext(constructorContext)

    const cleanConstructoModal = useCleanConstructorModal(props.id)

    const {
        postStory,
        putStory,

        postLocation,
        putLocation,

        postTask,
        putTask,

        postAction,
        putAction
    } = useConstructorModalQueries(props, cleanConstructoModal)

    const [form] = useForm()
    const [formComponent] = useState(useFormComponent(form, props))

    function onOk() {
        const taskId = form.getFieldValue('taskId')

        form.validateFields().then((body) => {
            switch (props.name) {
                case 'story':
                    if (props.action === 'add') {
                        postStory(body)
                    } else if (props.action === 'edit') {
                        putStory(body)
                    }
                    break
                case 'task':
                    if (props.action === 'add') {
                        postTask(body)
                    } else if (props.action === 'edit') {
                        putTask(body)
                    }
                    break
                case 'location':
                    if (props.action === 'add') {
                        postLocation(body)
                    } else if (props.action === 'edit') {
                        putLocation(body)
                    }
                    break
                case 'action':
                    if (props.action === 'add') {
                        postAction({ ...body, taskId })
                    } else if (props.action === 'edit') {
                        putAction(body)
                    }
                    break
            }
        })
    }

    return (
        <Modal
            width={getWidth(props.name)}
            title={<ConstructorModalTitle name={props.name} />}
            open={true}
            onCancel={() => {
                cleanConstructoModal()

                if (props.name === 'location' || props.name === 'task') {
                    setBufferActionIds([])
                }
            }}
            okButtonProps={props.action ? {} : { style: { display: 'none' } }}
            cancelText={props.action ? 'Відмінити' : 'Завершити'}
            okText={getOkText(props.action)}
            onOk={onOk}
        >
            {formComponent}
            {props.name === 'task' && props.action === 'edit' && bufferActionIds?.length <= 1 ? (
                <div style={{ color: 'orange', fontSize: 18 }}>
                    <WarningOutlined style={{ fontSize: 25 }} /> Майте на увазі, що завдання, яке має менше двох (2)
                    відмічених дій у цьому вікні, не буде відображатися на сторінці Гри у списку доступних завдань.
                </div>
            ) : null}
        </Modal>
    )
}
