import { AimOutlined, CheckSquareOutlined, ThunderboltOutlined, EnvironmentOutlined } from '@ant-design/icons'
import React from 'react'
import { ConstructorModalName } from '../../hooks/pages/Constructor/constuctor-modal'

type ModalTitleProps = {
    name: ConstructorModalName
}

export default function ConstructorModalTitle({ name }: ModalTitleProps) {
    const { title, icon } = getTitleInfo(name)

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon, { style: { marginRight: 7, fontSize: 20 } })}
            {title}
        </div>
    )
}

function getTitleInfo(name: ConstructorModalName) {
    let title: string
    let icon: any

    switch (name) {
        case 'action':
            title = 'Дія'
            icon = AimOutlined
            break
        case 'actions-manager':
            title = 'Менеджер дій'
            icon = AimOutlined
            break
        case 'task':
            title = 'Задача'
            icon = CheckSquareOutlined
            break
        case 'story':
            title = 'Історія'
            icon = ThunderboltOutlined
            break
        case 'location':
            title = 'Локація'
            icon = EnvironmentOutlined
            break
    }

    return { title, icon }
}
