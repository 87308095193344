import { FormInstance, Tree } from 'antd'
import { AntTreeNodeProps } from 'antd/lib/tree/Tree'
import { Key, useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { constructorContext } from '../../../contexts'
import { usePutAction } from '../../../hooks/store/actions.api'
import { IActionInfo, IActionsTreeElement } from '../../../utils/interfaces/action'
import ActionsTreeElement from './ActionsTreeElement'
import ActionsTreeHeader from './ActionsTreeHeader'

interface IActionsManagerProps {
    form?: FormInstance<any>
    mode?: ActionsManagerMode
    allowMultipleChecksOnOneLevel?: boolean
}

export enum ActionsManagerMode {
    RELATED,
    FREE
}

export default function ActionsManager({
    form,
    mode = ActionsManagerMode.RELATED,
    allowMultipleChecksOnOneLevel = false
}: IActionsManagerProps) {
    const {
        actionsState: [actions, setActions],
        bufferActionIdsState: [bufferActionIds, setBufferActionIds]
    } = useContext(constructorContext)

    const [treeData, setTreeData] = useState<IActionsTreeElement[]>([])

    const [keys, setKeys] = useState<{ [key: string]: string }>({})
    const [checkedKeys, setCheckedKeys] = useState<string[]>([])
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([])

    const putAction = usePutAction()

    useEffect(() => {
        if (mode === ActionsManagerMode.RELATED && bufferActionIds.length) {
            let checkedKey: string[] = []
            Object.keys(keys).forEach((key) => {
                if (bufferActionIds.includes(keys[key])) {
                    checkedKey.push(key)
                }
            })

            if (checkedKey.length) {
                setCheckedKeys([...checkedKeys, ...checkedKey])
            }
        }
    }, [keys])

    useEffect(() => {
        if (mode === ActionsManagerMode.RELATED) {
            setExpandedKeys(Array.from(new Set([...checkedKeys, ...expandedKeys])))
        }
    }, [checkedKeys])

    useEffect(() => {
        const currentTaskId = form?.getFieldValue('id')
        const currentTaskActions = currentTaskId ? actions.filter((action) => action.taskId === currentTaskId) : null

        if (currentTaskActions) {
            const newTreeData = configActions(currentTaskActions)

            if (newTreeData) {
                setTreeData(newTreeData)
            }
        }
    }, [actions])

    useEffect(() => {
        if (form) {
            form.setFieldValue('actionIds', bufferActionIds)
        }
    }, [bufferActionIds])

    useEffect(() => {
        if (!allowMultipleChecksOnOneLevel && checkedKeys.length) {
            setTreeData(configCheckboxDisabling(treeData))
        }
    }, [checkedKeys])

    // TODO: Refactor: too complex
    // TODO: Remove
    function onDrop(info: AntTreeNodeProps) {
        const dropKey: string = info.node.key
        const dragKey: string = info.dragNode.key
        const dragActionId: string = info.dragNode.id

        const dragParentActionIds = actions
            .filter((action) => action.actionIds.includes(dragActionId))
            .map((action) => action.id)
        const dragParentKeys = []
        for (const key in keys) {
            if (dragParentActionIds.includes(keys[key])) {
                dragParentKeys.push(key)
            }
        }
        const dragParentElements = getTreeDataElements(dragParentKeys)
        let dragParent: IActionsTreeElement | undefined
        for (const element of dragParentElements) {
            if (element.children.find((child) => child.key === dragKey)) {
                dragParent = element
                break
            }
        }

        const dropPos = info.node.pos
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

        const dragElem: IActionsTreeElement = {
            key: dragKey,
            title: info.dragNode.title,
            children: info.dragNode.children,
            id: info.dragNode.id
        }

        let actionsTreeData = treeData.filter(filterForDragKey)

        insertDragElem(actionsTreeData)

        setTreeData(actionsTreeData)

        updateActions()

        // Send actions udpate requests
        function updateActions() {
            const dropElem = getTreeDataElement(dropKey)

            const dropChildActionIds: string[] | undefined = dropElem?.children.map((child) => child.id)

            if (!dropChildActionIds) {
                return
            }

            const dragParentChildrenActionIds = dragParent?.children.map((child) => child.id)
            const dragParentActionId = dragParent?.id
            const dragParentKey: string | undefined = dragParent?.key

            // Update action, which was parent for action we dragged before dragging
            if (dragParentKey) {
                putAction(
                    {
                        actionIds: dragParentChildrenActionIds
                    },
                    dragParentActionId
                )
            }

            // If we dragged action to another parent action, we also update that new parent action
            if (dropKey !== dragParentKey) {
                const dropActionId = keys[dropKey]

                putAction(
                    {
                        actionIds: dropChildActionIds
                    },
                    dropActionId
                )
            }
        }

        function filterForDragKey(elem: IActionsTreeElement) {
            if (elem.key !== dragKey) {
                elem.children = elem.children.filter(filterForDragKey)
                return true
            }
        }

        function insertDragElem(actions: IActionsTreeElement[]) {
            for (let i = 0; i < actions.length; i++) {
                if (actions[i].key === dropKey) {
                    if (dropPosition === 0) {
                        actions[i].children.unshift(dragElem)
                    }

                    if (dropPosition === 1) {
                        if (i + 1 < actions.length) {
                            actions.splice(i + 1, 0, dragElem)
                        } else {
                            actions.push(dragElem)
                        }
                    }

                    return true
                }
                if (insertDragElem(actions[i].children)) return true
            }
        }
    }

    function configActions(specifiedActions: IActionInfo[]) {
        const newKeys: typeof keys = {}

        const actions: IActionInfo[] = JSON.parse(JSON.stringify(specifiedActions))

        if (!actions) {
            return
        }

        const recorded: string[] = []

        const actionsTreeElements = actions
            .map((action) => {
                if (recorded.includes(action.id)) {
                    return
                }

                return configElem(action)
            })
            .filter((actionsTreeElement): actionsTreeElement is IActionsTreeElement => !!actionsTreeElement)

        if (mode === ActionsManagerMode.FREE) {
            setExpandedKeys(Object.keys(newKeys))
        }

        setKeys(newKeys)

        return actionsTreeElements

        function configElem({ id, title, actionIds, taskId, event }: IActionInfo) {
            if (recorded.includes(id)) {
                return
            }
            recorded.push(id)
            // Unsorted
            const childActions = actions
                .filter((action) => actionIds.includes(action.id))
                .sort((a, b) => actionIds.indexOf(a.id) - actionIds.indexOf(b.id))

            const key = uuidv4()

            const actionsTreeElement: IActionsTreeElement = {
                key,
                id,
                title: <ActionsTreeElement id={id} title={title} taskId={taskId} event={event} />,
                children: childActions
                    .map((child) => {
                        return configElem(child)
                    })
                    .filter((actionsTreeElement): actionsTreeElement is IActionsTreeElement => !!actionsTreeElement)
            }

            newKeys[key] = id

            return actionsTreeElement
        }
    }

    function configCheckboxDisabling(actionsTreeElements: IActionsTreeElement[]) {
        const result: IActionsTreeElement[] = []
        const rootKeys = actionsTreeElements.map(({ key }) => key)

        for (const actionsTreeElement of actionsTreeElements) {
            result.push(
                _configCheckboxDisabling(
                    actionsTreeElement,
                    rootKeys.filter((key) => key !== actionsTreeElement.key)
                )
            )
        }

        return result

        function _configCheckboxDisabling(actionsTreeElement: IActionsTreeElement, neighbourKeys: string[]) {
            for (const neighbourKey of neighbourKeys) {
                const value = checkedKeys.includes(neighbourKey)
                actionsTreeElement.disableCheckbox = value
                if (value) {
                    break
                }
            }

            const newChildren: IActionsTreeElement[] = []
            const childrenKeys = actionsTreeElement.children.map(({ key }) => key)

            for (const child of actionsTreeElement.children) {
                newChildren.push(
                    _configCheckboxDisabling(
                        child,
                        childrenKeys.filter((key) => key !== child.key)
                    )
                )
            }

            actionsTreeElement.children = newChildren

            return actionsTreeElement
        }
    }

    function onCheck(
        checked:
            | Key[]
            | {
                  checked: Key[]
                  halfChecked: Key[]
              },
        info: AntTreeNodeProps
    ) {
        const actionId = info.node.id
        const currentKey = info.node.key
        // TODO: Investigate why "!" is required
        const isChecked = !info.node.checked

        const rawCheckedKeys: string[] = (checked as { checked: Key[]; halfChecked: Key[] }).checked as string[]

        const actionIdDublicationKeys: string[] = []

        // Search for actionId dublications
        for (const key in keys) {
            if (keys[key] === actionId && key !== currentKey) {
                actionIdDublicationKeys.push(key)
            }
        }

        let filteredCheckedKeys: string[] = []

        if (isChecked) {
            filteredCheckedKeys = Array.from(new Set([...rawCheckedKeys, ...actionIdDublicationKeys]))
        } else {
            filteredCheckedKeys = rawCheckedKeys.filter(
                (key) => ![...actionIdDublicationKeys, currentKey].includes(key)
            )
        }

        // TODO: sort filteredCheckedKeys ?

        const checkedActionIds = Array.from(new Set(filteredCheckedKeys.map((key) => keys[key]))).filter(
            (actionId) => actionId
        )

        setBufferActionIds(sortCheckedActionIds(checkedActionIds))
        setCheckedKeys(filteredCheckedKeys as string[])
    }

    function sortCheckedActionIds(checkedActionIds: string[]) {
        const result: string[] = []
        let i = 0

        function getNextCheckedActionId(children: IActionsTreeElement[]) {
            if (i === checkedActionIds.length) {
                return
            }

            for (const actionId of checkedActionIds) {
                const element = children.find((treeDataElement) => treeDataElement.id === actionId)

                if (element) {
                    // TODO: Remove actionId from checkedActionIds to decrease iterations count for checkedActionIds.length number (in optimization order)
                    result.push(actionId)
                    getNextCheckedActionId(element.children)
                    break
                }
            }

            i++
        }

        getNextCheckedActionId(treeData)

        return result
    }

    function getTreeDataElement(key: string, treeDataElement?: IActionsTreeElement): IActionsTreeElement | undefined {
        if (treeDataElement?.key === key) {
            return treeDataElement
        }

        for (const element of treeDataElement?.children || treeData) {
            if (element.key === key) {
                return element
            }

            const childElement = getTreeDataElement(key, element)

            if (childElement) {
                return childElement
            }
        }
    }

    function getTreeDataElements(keys: string[], treeDataElement?: IActionsTreeElement): IActionsTreeElement[] {
        const result: IActionsTreeElement[] = []

        if (treeDataElement && keys.includes(treeDataElement.key)) {
            result.push(treeDataElement)
        }

        for (const element of treeDataElement?.children || treeData) {
            if (keys.includes(element.key)) {
                result.push(element)
            }

            const childElements = getTreeDataElements(keys, element)

            if (childElements) {
                result.push(...childElements)
            }
        }

        return result
    }

    function onExpand(expandedKeys: Key[]) {
        if (mode === ActionsManagerMode.FREE) {
            return
        }

        if (!expandedKeys.length) {
            setExpandedKeys(checkedKeys)
        } else {
            setExpandedKeys(expandedKeys)
        }
    }

    return (
        <>
            <ActionsTreeHeader taskId={form?.getFieldValue('id')} />
            <Tree
                style={{ overflowX: 'scroll', overflowY: 'clip', minWidth: '100%', minHeight: '300px' }}
                checkable={mode === ActionsManagerMode.RELATED}
                checkStrictly
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                showLine
                treeData={treeData}
                expandedKeys={expandedKeys}
                onExpand={onExpand}
            />
        </>
    )
}
