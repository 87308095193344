import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserInfo, IUserProgressInfo } from '../utils/interfaces/user'

interface IState {
    user: IUserInfo | null
    userProgress: IUserProgressInfo | null
}

const initialState: IState = {
    user: null,
    userProgress: {
        taskProgresses: [],
        currentTaskId: null
    }
}

export const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<IUserInfo | null>) {
            state.user = action.payload
        },
        setUserProgress(state, action: PayloadAction<IUserProgressInfo | null>) {
            state.userProgress = action.payload
        }
    }
})

export default gameSlice.reducer
