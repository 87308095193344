import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { useDeleteStory } from '../../../hooks/store/stories.api'

export default function DeleteStoryBtn() {
    const deleteStory = useDeleteStory()

    return (
        <Popconfirm
            title="Всі задачі історії будуть видалені! Ви впевнені?"
            okText="Так"
            cancelText="Ні"
            onConfirm={deleteStory}
        >
            <DeleteOutlined /> Видалити
        </Popconfirm>
    )
}
