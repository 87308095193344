import { useEffect } from 'react'
import { useFetchSettingsQuery, usePutSettingsMutation } from '../../store/settings.api'
import { ISettings } from '../../utils/interfaces/settings'
import { errorNotification } from '../../utils/notifications'
import { useToken } from '../auth'

export function useFetchSettings(callback: (result: ISettings) => void) {
    const token = useToken()

    const fetchSettingsQuery = useFetchSettingsQuery(undefined, { skip: !token })

    useEffect(() => {
        if (fetchSettingsQuery.data) {
            callback(fetchSettingsQuery.data.result)
        }
    }, [fetchSettingsQuery.data])
}

export function usePutSettings() {
    const [putSettingsMutation] = usePutSettingsMutation()

    return (settings: ISettings) => {
        putSettingsMutation({ settings }).then((value: any) => {
            if (value.data) {
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Оновлення налаштувань не вдалося')
            }
        })
    }
}
