import { Dropdown, Tooltip } from 'antd'
import ActionControls from './ActionControls'

interface IActionsTreeElementProps {
    title: string
    id: string
    taskId: string
    event?: string
}

export default function ActionsTreeElement({ title, id, taskId, event }: IActionsTreeElementProps) {
    const actionTreeElement = (
        <Dropdown overlay={<ActionControls id={id} taskId={taskId} />} placement="topLeft" trigger={['click']}>
            <div style={{ width: 400 }}>{title}</div>
        </Dropdown>
    )

    if (event) {
        return (
            <Tooltip overlayStyle={{ maxWidth: '30vw' }} title={event} placement="right">
                {actionTreeElement}
            </Tooltip>
        )
    }

    return actionTreeElement
}
