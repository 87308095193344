import { createApi } from '@reduxjs/toolkit/query/react'
import { INotificationPostBody, INotificationPostResponse } from '../utils/interfaces/notification'
import { baseQueryWithRefresh } from '../utils/store'

export const notificationsApi = createApi({
    reducerPath: 'notifications/api',
    baseQuery: baseQueryWithRefresh,
    endpoints: (builder) => ({
        postNotification: builder.mutation<INotificationPostResponse, { body: INotificationPostBody }>({
            query: ({ body }) => ({
                method: 'POST',
                url: '/api/private/notifications',
                body
            })
        })
    })
})

export const { usePostNotificationMutation } = notificationsApi
