import { createApi } from '@reduxjs/toolkit/query/react'
import {
    IFetchLocationsResponse,
    ILocationDeleteResponse,
    ILocationPostBody,
    ILocationPostResponse,
    ILocationPutBody,
    ILocationPutResponse
} from '../utils/interfaces/location'
import { baseQueryWithRefresh } from '../utils/store'

export const locationsApi = createApi({
    reducerPath: 'locations/api',
    baseQuery: baseQueryWithRefresh,
    endpoints: (builder) => ({
        postLocation: builder.mutation<ILocationPostResponse, { body: ILocationPostBody }>({
            query: ({ body }) => ({
                method: 'POST',
                url: '/api/private/locations',
                body
            })
        }),
        fetchLocations: builder.query<IFetchLocationsResponse, void>({
            query: () => ({
                url: '/api/private/locations',
                params: {
                    order: `timestamp,asc`
                }
            })
        }),
        putLocation: builder.mutation<ILocationPutResponse, { id: string; body: ILocationPutBody }>({
            query: ({ id, body }) => ({
                method: 'PUT',
                url: `/api/private/locations/${id}`,
                body
            })
        }),
        deleteLocation: builder.mutation<ILocationDeleteResponse, { id: string }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `/api/private/locations/${id}`
            })
        })
    })
})

export const { usePostLocationMutation, useFetchLocationsQuery, usePutLocationMutation, useDeleteLocationMutation } =
    locationsApi
