import { PlusOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useState, useContext } from 'react'
import { constructorContext } from '../../../contexts'

interface IAddActionBtnProps {
    taskId?: string
}

export default function AddActionBtn({ taskId }: IAddActionBtnProps) {
    const { useConstructorModal } = useContext(constructorContext)
    const initialValuesState = useState({ taskId })
    const createConstructorModal = useConstructorModal({
        name: 'action',
        action: 'add',
        initialValuesState
    })

    const btn = (
        <Button disabled={!taskId} onClick={createConstructorModal} icon={<PlusOutlined />} type="primary">
            Додати нову
        </Button>
    )

    if (taskId) {
        return btn
    }

    return (
        <Tooltip title='Щоб додати дію, спочатку насніть "Створити", а потім відкрийте форму редагування задачі'>
            {btn}
        </Tooltip>
    )
}
