import { RedoOutlined } from '@ant-design/icons'
import { useDeleteTaskProgress } from '../../../../hooks/store/task-progresses.api'
import { Popconfirm } from 'antd'

interface IDeleteTaskProgressBtnProps {
    id: string
}

export default function DeleteTaskProgressBtn({ id }: IDeleteTaskProgressBtnProps) {
    const deleteTaskProgress = useDeleteTaskProgress()

    return (
        <Popconfirm
            title="Прогрес задачі буде видалено. Ви впевнені?"
            okText="Так"
            cancelText="Ні"
            onConfirm={() => deleteTaskProgress(id)}
        >
            <RedoOutlined style={{ color: '#1677ff', marginRight: '3px' }} />
        </Popconfirm>
    )
}
