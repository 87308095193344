import { useContext, useEffect } from 'react'
import { constructorContext } from '../../contexts'
import {
    useDeleteTaskMutation,
    useDublicateTaskMutation,
    useFetchTasksQuery,
    usePostTaskMutation,
    usePutTaskMutation
} from '../../store/tasks.api'
import { getChanges } from '../../utils/components/Constructor/constructor-modal'
import { ITaskInfo, ITaskPostBody, ITaskPutBody } from '../../utils/interfaces/task'
import { errorNotification } from '../../utils/notifications'
import { useToken } from '../auth'
import { IActionInfo } from '../../utils/interfaces/action'

export function useFetchTasks(setTasks: React.Dispatch<React.SetStateAction<ITaskInfo[]>>) {
    const token = useToken()

    const fetchTasksQuery = useFetchTasksQuery(undefined, { skip: !token })

    useEffect(() => {
        if (fetchTasksQuery.data?.result) {
            setTasks(fetchTasksQuery.data.result)
        }
    }, [fetchTasksQuery.data])
}

export function usePostTask(callback?: () => void) {
    const {
        storiesState: [stories, setStories],
        selectedStoryIdState: [selectedStoryId],
        tasksState: [tasks, setTasks]
    } = useContext(constructorContext)

    const [postTaskMutation] = usePostTaskMutation()

    return (body: ITaskPostBody) => {
        postTaskMutation({
            storyId: selectedStoryId,
            body
        }).then((value: any) => {
            if (value.data) {
                const task: ITaskInfo = value?.data?.result
                setTasks([...tasks, task])
                setStories(
                    stories.map((story) => {
                        if (story.id === selectedStoryId) {
                            return { ...story, taskIds: [...story.taskIds, task.id] }
                        }
                        return story
                    })
                )

                callback && callback()
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Додати завдання не вдалося')
            }
        })
    }
}

export function usePutTask(
    initialValuesState?: [ITaskInfo, React.Dispatch<React.SetStateAction<ITaskInfo>>],
    callback?: () => void
) {
    const [tasks, setTasks] = useContext(constructorContext).tasksState

    const [putTaskMutation] = usePutTaskMutation()

    return (body: ITaskPutBody) => {
        if (!initialValuesState) {
            throw new Error('No initialValuesState passed!')
        }

        const [initialValues, setInitialValues] = initialValuesState

        const putBody = getChanges(initialValues, body)
        if (!Object.keys(putBody).length) {
            return errorNotification('Не оновлено жодного поля')
        }

        putTaskMutation({ id: initialValues.id, body: putBody }).then((value: any) => {
            if (value.data) {
                const newTask: ITaskInfo = value?.data?.result

                setTasks([
                    ...tasks.map((task) => {
                        if (task.id === newTask.id) {
                            return newTask
                        }
                        return task
                    })
                ])
                setInitialValues(newTask)

                callback && callback()
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Оновити завдання не вдалося')
            }
        })
    }
}

export function useDeleteTask(id: string) {
    const {
        tasksState: [tasks, setTasks],
        storiesState: [stories, setStories],
        selectedStoryIdState: [selectedStoryId, setSelectedStoryId]
    } = useContext(constructorContext)

    const [deleteTaskMutation] = useDeleteTaskMutation()

    return () => {
        deleteTaskMutation({ id }).then((value: any) => {
            if (value.data) {
                const newTasks = [...tasks.filter((task) => !(task.id === id))]
                setTasks(newTasks)
                setStories(
                    stories.map((story) => {
                        if (story.id === selectedStoryId) {
                            return { ...story, taskIds: story.taskIds.filter((taskId) => taskId !== id) }
                        }
                        return story
                    })
                )
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Видалити завдання не вдалося')
            }
        })
    }
}

export function useDublicateTask(storyId: string, oldTaskId: string, callback?: () => void) {
    const {
        storiesState: [stories, setStories],
        selectedStoryIdState: [selectedStoryId],
        tasksState: [tasks, setTasks],
        actionsState: [actions, setActions]
    } = useContext(constructorContext)

    const [dublicateTaskMutation] = useDublicateTaskMutation()

    return () => {
        dublicateTaskMutation({ storyId, oldTaskId }).then((value: any) => {
            if (value.data) {
                // Add task on client side
                const task: ITaskInfo = value?.data?.result.task
                setTasks([...tasks, task])
                setStories(
                    stories.map((story) => {
                        if (story.id === selectedStoryId) {
                            return { ...story, taskIds: [...story.taskIds, task.id] }
                        }
                        return story
                    })
                )

                // Add actions on client side
                const newActions: IActionInfo[] = value?.data?.result.actions

                setActions([...actions, ...newActions])

                callback && callback()
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Здублювати завдання не вдалося')
            }
        })
    }
}
