import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { useDeleteLocation } from '../../../hooks/store/locations.api'

interface IDeleteLocationBtnProps {
    id: string
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
    setIsHover: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DeleteLocationBtn({ id, openState, setIsHover }: IDeleteLocationBtnProps) {
    const [open, setOpen] = openState

    const deleteLocation = useDeleteLocation(id)

    return (
        <Popconfirm
            placement="right"
            title="Ви впевнені?"
            okText="Так"
            cancelText="Ні"
            open={open}
            onOpenChange={(open) => {
                setOpen(open)
                setIsHover(open)
            }}
            onConfirm={deleteLocation}
        >
            <div className="delete-location-btn">
                <DeleteOutlined key="delete" style={{ fontSize: 20, color: 'white' }} />
            </div>
        </Popconfirm>
    )
}
