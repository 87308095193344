import { EditOutlined } from '@ant-design/icons'

interface IEditRoleBtnProps {
    id: string
    title: string
    setRoleEdit: React.Dispatch<React.SetStateAction<{ id: string; newTitle: string; title: string } | null>>
}

export default function EditRoleBtn({ id, title, setRoleEdit }: IEditRoleBtnProps) {
    return <EditOutlined onClick={() => setRoleEdit({ id, newTitle: title, title })} />
}
