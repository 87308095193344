import { useContext } from 'react'
import { constructorContext } from '../../../contexts'
import { ILocationInfo } from '../../../utils/interfaces/location'
import AddLocationBtn from './AddLocationBtn'
import Location from './Location'

export default function Locations() {
    const {
        locationsState: [locations, setLocations]
    } = useContext(constructorContext)

    return (
        <div className="locations-container">
            <AddLocationBtn />
            <div className="locations">
                {locations.map((location: ILocationInfo) => (
                    <Location location={location} key={location.id} />
                ))}
            </div>
        </div>
    )
}
