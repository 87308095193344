import Actions from './Actions'
import Event from './Event'
import { useCurrentTaskProgress } from '../../../hooks/pages/Game/utils'
import TaskResult from './TaskResult'

export default function Location() {
    const currentTaskProgress = useCurrentTaskProgress()

    return currentTaskProgress?.status === 'completed' ? (
        <TaskResult />
    ) : (
        <div className="location-game-container">
            <Event />
            <Actions />
        </div>
    )
}
