import { Select } from 'antd'
import { useContext } from 'react'
import { gameContext } from '../../../../contexts'
import { useAppSelector } from '../../../../hooks/store'
import { IRoleInfo } from '../../../../utils/interfaces/role'

export default function SelectRole() {
    const {
        rolesState: [roles, setRoles],
        roleIdState: [roleId, setRoleId]
    } = useContext(gameContext)

    const userProgress = useAppSelector((state) => state.gameReducer.userProgress)

    const onChange = (value: string) => {
        setRoleId(value)
    }

    return (
        <div style={{ flex: 1 }}>
            {roleId && (
                <Select
                    options={roles.map((role: IRoleInfo) => ({
                        value: role.id,
                        label: role.title
                    }))}
                    defaultValue={roleId}
                    style={{ width: 120 }}
                    onChange={onChange}
                    disabled={!!userProgress?.currentTaskId}
                />
            )}
        </div>
    )
}
