import { useState, useContext } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { constructorContext } from '../../../contexts'

export default function EditStoryBtn() {
    const {
        storiesState: [stories],
        selectedStoryIdState: [selectedStoryId],
        useConstructorModal
    } = useContext(constructorContext)

    const initialValuesState = useState(stories.find((story) => story.id === selectedStoryId))

    const createConstructorModal = useConstructorModal({
        name: 'story',
        action: 'edit',
        initialValuesState
    })

    return (
        <div onClick={createConstructorModal}>
            <EditOutlined /> Редагувати
        </div>
    )
}
