import Tree, { DataNode } from 'antd/lib/tree'
import { useState, useEffect, useContext, useCallback } from 'react'
import { gameContext } from '../../../../contexts'
import TreeDataTaskTitle from './TreeDataTaskTitle'
import { useAppSelector } from '../../../../hooks/store'

export default function UserProgress() {
    const {
        tasksState: [tasks, setTasks],
        storiesState: [stories, setStories],
        roleIdState: [roleId, setRoleId]
    } = useContext(gameContext)

    const userProgress = useAppSelector((state) => state.gameReducer.userProgress)

    const [treeData, setTreeData] = useState<DataNode[]>([])

    const getTreeData: () => DataNode[] = () => {
        return stories.map((story) => ({
            title: story.title,
            key: story.id,
            children: story.taskIds
                .map((taskId) => {
                    const task = tasks.filter((task) => task.roleId === roleId).find((task) => task.id === taskId)
                    if (!task) {
                        return null
                    }
                    const taskProgress = userProgress?.taskProgresses.find(
                        (taskProgress) => taskProgress.taskId === taskId
                    )
                    return {
                        title: (
                            <TreeDataTaskTitle
                                taskStatus={taskProgress?.status}
                                currentTaskId={userProgress?.currentTaskId || null}
                                task={task}
                            />
                        ),
                        key: task.id,
                        actionIds: task.actionIds
                    }
                })
                .filter((task) => task?.actionIds && task.actionIds.length > 1) as DataNode[]
        }))
    }

    useEffect(() => {
        setTreeData(getTreeData())
    }, [userProgress, roleId])

    return <Tree selectable={false} treeData={treeData} />
}
