import React, { useContext, useEffect, useState } from 'react'
import {
    useDeleteStoryMutation,
    useFetchStoriesQuery,
    usePostStoryMutation,
    usePutStoryMutation
} from '../../store/stories.api'
import { useToken } from '../auth'
import { IStoryInfo, IStoryPostBody, IStoryPutBody } from '../../utils/interfaces/story'
import { errorNotification } from '../../utils/notifications'
import { constructorContext } from '../../contexts'
import { getChanges } from '../../utils/components/Constructor/constructor-modal'

export function useFetchStories(callback: (result: IStoryInfo[]) => void) {
    const token = useToken()

    const fetchStoriesQuery = useFetchStoriesQuery(undefined, { skip: !token })

    useEffect(() => {
        if (fetchStoriesQuery.data?.result) {
            callback(fetchStoriesQuery.data.result)
        }
    }, [fetchStoriesQuery.data])
}

export function usePostStory(callback?: () => void) {
    const {
        storiesState: [stories, setStories],
        selectedStoryIdState: [selectedStoryId, setSelectedStoryId]
    } = useContext(constructorContext)

    const [postStoryMutation] = usePostStoryMutation()

    return (body: IStoryPostBody) => {
        postStoryMutation({
            body
        }).then((value: any) => {
            if (value.data) {
                const story = value?.data?.result
                setStories([...stories, story])
                setSelectedStoryId(story.id)

                callback && callback()
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Додати історію не вдалося')
            }
        })
    }
}

export function usePutStory(
    initialValuesState?: [IStoryInfo, React.Dispatch<React.SetStateAction<IStoryPutBody>>],
    callback?: () => void
) {
    const {
        storiesState: [stories, setStories]
    } = useContext(constructorContext)

    const [putStoryMutation] = usePutStoryMutation()

    return (body: IStoryPutBody) => {
        if (!initialValuesState) {
            throw new Error('No initialValuesState passed!')
        }

        const [initialValues, setInitialValues] = initialValuesState

        const putBody = getChanges(initialValues, body)
        if (!Object.keys(putBody).length) {
            return errorNotification('Не оновлено жодного поля')
        }

        putStoryMutation({
            id: initialValues.id,
            body: putBody
        }).then((value: any) => {
            if (value.data) {
                const newStory: IStoryInfo = value.data.result
                setStories(
                    stories.map((story) => {
                        if (story.id === newStory.id) {
                            return newStory
                        }
                        return story
                    })
                )
                setInitialValues(newStory)

                callback && callback()
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Оновити історію не вдалося')
            }
        })
    }
}

export function useDeleteStory() {
    const {
        storiesState: [stories, setStories],
        selectedStoryIdState: [selectedStoryId, setSelectedStoryId],
        tasksState: [tasks, setTasks]
    } = useContext(constructorContext)

    const [deleteStoryMutation] = useDeleteStoryMutation()

    return () => {
        if (!selectedStoryId) {
            return
        }

        deleteStoryMutation({
            id: selectedStoryId
        }).then((value: any) => {
            if (value.data) {
                let taskIds: string[] = []
                const newStories = stories.filter((story) => {
                    if (story.id === selectedStoryId) {
                        taskIds = story.taskIds
                        return false
                    }

                    return true
                })

                const newTasks = tasks.filter((task) => !taskIds.includes(task.id))

                setTasks(newTasks)
                setStories(newStories)

                if (newStories.length) {
                    setSelectedStoryId(newStories[0].id)
                } else {
                    setSelectedStoryId(null)
                }
            } else {
                const error = value.error?.msg || value.error?.data?.error
                errorNotification(error, 'Видалити історію не вдалося')
            }
        })
    }
}
