import { useEffect } from 'react'
import { CustomerServiceOutlined } from '@ant-design/icons'
import { parseUser } from '../../utils/jwt'
import { useState } from 'react'
import { IUserState } from '../../utils/interfaces/user'
import { useToken } from '../../hooks/auth'
import { getTextForStatus } from '../../utils/components/Forbidden/forbidden-content'

export default function ForbiddenContent() {
    const token = useToken()
    const [user, setUser] = useState<IUserState | null>(parseUser(token))

    useEffect(() => {
        setUser(parseUser(token))
    }, [token])

    if (!user) {
        return null
    }

    return user.active ? (
        <div className="forbidden-content">
            <div className="message">
                Ваш акаунт <span style={{ color: 'red' }}>{getTextForStatus(user.status)}</span>
            </div>
            <div className="message">
                Зв'яжіться з адміністратором для отримання доступу <CustomerServiceOutlined style={{ fontSize: 50 }} />
            </div>
        </div>
    ) : (
        <div className="forbidden-content">
            <div className="message">На {user.email} надіслано лист для підтвердження електронної пошти</div>
        </div>
    )
}
