import Constructor from '../pages/Constructor'
import Dashboard from '../pages/Dashboard'
import Game from '../pages/Game'
import Settings from '../pages/Settings'
import Users from '../pages/Users'
import { RouteObject } from 'react-router-dom'
import AdminLayout from '../components/AdminLayout'
// import QuickStartGuide from '../components/Guide/GuideListElem/QuickStartGuide'
// import DashboardGuide from '../components/Guide/GuideListElem/DashboardGuide'
// import SettingsGuide from '../components/Guide/GuideListElem/SettingsGuide'
// import UsersGuide from '../components/Guide/GuideListElem/UsersGuide'
// import ConstructorGuide from '../components/Guide/GuideListElem/ConstructorGuide'
// import GameGuide from '../components/Guide/GuideListElem/GameGuide'
// import Guide from '../pages/Guide'

const privateRoutes: RouteObject[] = [
    {
        path: '/admin',
        element: <AdminLayout />,
        children: [
            {
                path: '/admin',
                element: <Dashboard />
            },
            {
                path: '/admin/users',
                element: <Users />
            },
            {
                path: '/admin/constructor',
                element: <Constructor />
            },
            {
                path: '/admin/settings',
                element: <Settings />
            }
            // TODO: Actualize guide page
            // {
            //     path: '/admin/guide',
            //     children: [
            //         {
            //             path: '/admin/guide',
            //             element: <Guide />
            //         },
            //         {
            //             path: '/admin/guide/quick-start',
            //             element: <QuickStartGuide />
            //         },
            //         {
            //             path: '/admin/guide/dashboard',
            //             element: <DashboardGuide />
            //         },
            //         {
            //             path: '/admin/guide/settings',
            //             element: <SettingsGuide />
            //         },
            //         {
            //             path: '/admin/guide/users',
            //             element: <UsersGuide />
            //         },
            //         {
            //             path: '/admin/guide/constructor',
            //             element: <ConstructorGuide />
            //         },
            //         {
            //             path: '/admin/guide/game',
            //             element: <GameGuide />
            //         }
            //     ]
            // }
        ]
    },
    {
        path: '/game',
        element: <Game />
    }
]

export default privateRoutes
