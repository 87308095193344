import { Dropdown, Menu } from 'antd'
import { useState } from 'react'
import UserAvatar from './UserAvatar'
import useAdminPageMenuItem from '../../Game/GameLayout/GameHeader/useAdminPageMenuItem'
import ReportBugModal from './ReportBugModal'
import useLogoutMenuItem from './useLogoutMenuItem'
import useReportBugMenuItem from './useReportBugMenuItem'

export default function UserDropdown() {
    const [isReportBugModalVisible, setIsReportBugModalVisible] = useState(false)

    return (
        <>
            <Dropdown
                overlay={
                    <Menu
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch'
                        }}
                        items={[
                            useAdminPageMenuItem(),
                            useReportBugMenuItem(setIsReportBugModalVisible),
                            useLogoutMenuItem()
                        ]}
                    />
                }
                arrow={{ pointAtCenter: true }}
                trigger={['click']}
                placement="bottomLeft"
            >
                <div style={{ marginLeft: '20px' }}>
                    <UserAvatar />
                </div>
            </Dropdown>
            <ReportBugModal open={isReportBugModalVisible} setOpen={setIsReportBugModalVisible} />
        </>
    )
}
