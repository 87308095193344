import { Tabs } from 'antd'
import { useContext } from 'react'
import { constructorContext } from '../../../contexts'
import { IStoryInfo } from '../../../utils/interfaces/story'
import AddStoryBtn from './AddStoryBtn'
import StoryTab from './StoryTab'

export default function StoryTabs() {
    const {
        storiesState: [stories, setStories],
        selectedStoryIdState: [selectedStoryId, setSelectedStoryId]
    } = useContext(constructorContext)

    return (
        <Tabs
            activeKey={selectedStoryId || ''}
            onChange={(key) => setSelectedStoryId(key)}
            tabBarExtraContent={<AddStoryBtn />}
            style={{ flex: 1 }}
        >
            {stories.map((story: IStoryInfo) => StoryTab({ tabKey: story.id, story, selectedStoryId, key: story.id }))}
        </Tabs>
    )
}
