import { gameSlice } from '../../store/game.reducer'
import { useDeleteTaskProgressMutation } from '../../store/task-progresses.api'
import { errorNotification } from '../../utils/notifications'
import { useAppDispatch, useAppSelector } from './'

export function useDeleteTaskProgress() {
    const dispatch = useAppDispatch()
    const userProgress = useAppSelector((state) => state.gameReducer.userProgress)
    const [deleteTaskProgressMutation] = useDeleteTaskProgressMutation()

    return (id: string) => {
        if (!userProgress) {
            return
        }

        deleteTaskProgressMutation({
            id
        }).then((value: any) => {
            const error = value.error?.msg || value.error?.data?.error
            if (error) {
                errorNotification(error, 'Видалити прогрес завдання не вдалося!')
            } else {
                let taskId: string | undefined
                const newUserProgress = {
                    ...userProgress,
                    taskProgresses: userProgress.taskProgresses.filter((taskProgress) => {
                        if (taskProgress.id == id) {
                            taskId = taskProgress.taskId
                        }

                        return taskProgress.id !== id
                    })
                }

                if (userProgress.currentTaskId === taskId) {
                    newUserProgress.currentTaskId = null
                }

                dispatch(gameSlice.actions.setUserProgress(newUserProgress))
            }
        })
    }
}
