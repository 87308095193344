import { Layout, Typography } from 'antd'
import { useContext, useEffect, useState } from 'react'
import GameHeader from './GameHeader/GameHeader'
import Sidebar from './Sidebar/Sidebar'
import { gameContext } from '../../../contexts'

const { Content } = Layout

interface IGameLayoutProps {
    children?: React.ReactNode
}

export default function GameLayout({ children }: IGameLayoutProps) {
    const {
        locationIdState: [locationId, setLocationId],
        locationsState: [locations, setLocations],
        isSidebarVisibleState: [isVisible, setIsVisible]
    } = useContext(gameContext)

    const [locationTitle, setLocationTitle] = useState<string | null>(null)
    const [locationBackground, setLocationBackground] = useState<string | null>(null)

    useEffect(() => {
        const location = locationId ? locations.find((location) => location.id === locationId) : null

        if (location?.title) {
            setLocationTitle(location.title)
        } else {
            setLocationTitle('Поточний прогрес')
        }

        if (location?.background) {
            setLocationBackground(location.background)
        } else {
            setLocationBackground(null)
        }
    }, [locationId])

    return (
        <>
            <Layout className="site-layout" style={{ minHeight: '100vh' }}>
                <GameHeader />

                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        backgroundImage: `url(${locationBackground})` || 'none',
                        display: 'flex'
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            backgroundColor: 'rgba(255,255,255,0.35)',
                            width: '100%',
                            padding: 24
                        }}
                    >
                        <Typography.Title level={1}>{locationTitle}</Typography.Title>
                        {children}
                    </div>
                </Content>
                <Sidebar open={isVisible} setOpen={setIsVisible} />
            </Layout>
        </>
    )
}
