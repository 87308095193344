import { EditOutlined } from '@ant-design/icons'
import { useContext, useState } from 'react'
import { constructorContext } from '../../../contexts'

interface IEditActionBtnProps {
    id: string
}

export default function EditActionBtn({ id }: IEditActionBtnProps) {
    const {
        actionsState: [actions],
        useConstructorModal
    } = useContext(constructorContext)

    const initialValuesState = useState(actions.find((action) => action.id === id))

    const createConstructorModal = useConstructorModal({
        name: 'action',
        action: 'edit',
        initialValuesState
    })

    return (
        <div style={{ padding: 12 }} onClick={createConstructorModal}>
            <EditOutlined
                style={{
                    color: '#8C8C8C',
                    fontSize: 17
                }}
            />
        </div>
    )
}
