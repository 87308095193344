import { useContext, useEffect } from 'react'
import { gameContext } from '../../contexts'
import { useAppSelector } from '../../hooks/store'
import GameLayout from './GameLayout/GameLayout'
import CurrentTask from './CurrentTask'
import Location from './Location/Location'
import Statistics from './Statistics'

export default function GameWrapper() {
    const {
        locationIdState: [locationId, setLocationId],
        tasksState: [tasks, setTasks]
    } = useContext(gameContext)

    const userProgress = useAppSelector((state) => state.gameReducer.userProgress)

    useEffect(() => {
        if (!userProgress) {
            return setLocationId(null)
        }

        const task = userProgress.currentTaskId ? tasks.find((task) => task.id === userProgress.currentTaskId) : null

        setLocationId(task?.locationId || null)
    }, [userProgress])

    return (
        <GameLayout>
            {locationId ? <Location /> : <Statistics />}

            {userProgress?.currentTaskId ? <CurrentTask /> : null}
        </GameLayout>
    )
}
