import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithRefresh } from '../utils/store'

export const taskProgressesApi = createApi({
    reducerPath: 'task-progresse/api',
    baseQuery: baseQueryWithRefresh,
    endpoints: (builder) => ({
        deleteTaskProgress: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `/api/private/task-progresses/${id}`
            })
        })
    })
})

export const { useDeleteTaskProgressMutation } = taskProgressesApi
