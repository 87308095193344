import { useEffect, useState } from 'react'
import Setting from '../components/Settings/Settings'
import { useTitle } from '../hooks/pages/layout'
import { useFetchSettings, usePutSettings } from '../hooks/store/settings.api'
import { ISettings } from '../utils/interfaces/settings'

export default function Settings() {
    useTitle('Налаштування')

    const [settings, setSettings] = useState<ISettings | null>(null)
    const [settingsStateUpdateCounter, setSettingsStateUpdateCounter] = useState(0)

    useFetchSettings(setSettings)
    const putSettings = usePutSettings()

    useEffect(() => {
        if (settings) {
            // TODO: Refactor: code smell. Making counter is deprecated
            setSettingsStateUpdateCounter(settingsStateUpdateCounter + 1)
            if (settingsStateUpdateCounter > 0) {
                putSettings(settings)
            }
        }
    }, [settings])

    return (
        <>
            {settings && (
                <div>
                    <Setting settingsState={[settings, setSettings]} title="Реєстрація дозволена" setting="signup" />

                    <Setting
                        settingsState={[settings, setSettings]}
                        title="Послугу сповіщень увімкнено"
                        setting="notificationsService"
                    />
                </div>
            )}
        </>
    )
}
