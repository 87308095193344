import AddChildActionBtn from './AddChildActionBtn'
import CopyActionBtn from './CopyActionBtn'
import DeleteActionBtn from './DeleteActionBtn'
import EditActionBtn from './EditActionBtn'
import { Menu } from 'antd'

interface IActionControlsProps {
    id: string
    taskId: string
}

export default function ActionControls({ id, taskId }: IActionControlsProps) {
    return (
        <Menu
            className="action-constrols"
            style={{ display: 'flex', width: '164px' }}
            items={[
                {
                    key: 1,
                    label: <AddChildActionBtn parentActionId={id} taskId={taskId} />
                },
                {
                    key: 2,
                    label: <EditActionBtn id={id} />
                },
                {
                    key: 3,
                    label: <DeleteActionBtn id={id} />
                },
                {
                    key: 4,
                    label: <CopyActionBtn id={id} />
                }
            ]}
        />
    )
}
