import { PlusSquareOutlined } from '@ant-design/icons'
import { useState, useContext } from 'react'
import { constructorContext } from '../../../contexts'

interface IAddChildActionBtn {
    parentActionId: string
    taskId: string
}

export default function AddChildActionBtn({ parentActionId, taskId }: IAddChildActionBtn) {
    const { useConstructorModal } = useContext(constructorContext)
    const initialValuesState = useState({ taskId })
    const createConstructorModal = useConstructorModal({
        name: 'action',
        action: 'add',
        initialValuesState,
        queryParams: {
            parentActionId
        }
    })

    return (
        <div style={{ padding: 12 }} onClick={createConstructorModal}>
            <PlusSquareOutlined
                style={{
                    color: '#8C8C8C',
                    fontSize: 17
                }}
            />
        </div>
    )
}
