import { Empty } from 'antd'
import { useContext } from 'react'
import { constructorContext } from '../../../contexts'
import Tasks from '../Tasks/Tasks'
import AddStoryBtn from './AddStoryBtn'

export default function Story() {
    const {
        selectedStoryIdState: [selectedStoryId]
    } = useContext(constructorContext)

    return selectedStoryId ? (
        <Tasks />
    ) : (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                height: '90%'
            }}
        >
            <Empty description="Немає жодної істоії">
                <AddStoryBtn button={true} />
            </Empty>
        </div>
    )
}
