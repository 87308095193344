import { PlusOutlined } from '@ant-design/icons'
import { useContext } from 'react'
import { constructorContext } from '../../../contexts'

export default function AddTaskBtn() {
    const { useConstructorModal } = useContext(constructorContext)

    const createConstructorModal = useConstructorModal({
        name: 'task',
        action: 'add'
    })

    return (
        <div className="add-task-btn" onClick={createConstructorModal}>
            <PlusOutlined style={{ fontSize: '40px', color: '#ffffff', margin: 0 }} />
        </div>
    )
}
